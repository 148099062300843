import React from 'react'
import SetACCharge from './subsetting/SetACCharge'
import SpecialOffer from './subsetting/SpecialOffer'
import SendOfferNotification from './subsetting/SendOfferNotification'
import SetGST from './subsetting/SetGST'
import { useCloud } from '../../hooks/useCloud'
import SettingHeading from './settingHeading/SettingHeading'
const Setting = () => {
  const { testNotification } = useCloud()
  return (
    <>
    
      {/* <SettingHeading/> */}
    <div className="p-8">

      <div className='section'>
        <button className='btn px-4 py-2 text-sm font-medium rounded-md transition bg-green-500 text-white hover:bg-green-600' onClick={testNotification}> Test Digital order notification</button>
      </div>
    <SetACCharge />
    {/* <SpecialOffer />
    <SendOfferNotification />
    <SetGST /> */}
    {/* <MoreSettings /> */}
  </div>
    </>
  )
}

export default Setting