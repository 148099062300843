import React, { useEffect, useState } from "react";
import {
  addCategoryAPI,
  allCatrgories,
  deletItem,
} from "./MenuService/Category.Service";
import {
  addItemAPI,
  getAllItems,
  outOfStock,
  updateITem,
} from "./MenuService/Item.service";
import store from "../../store/index";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useCloud } from "../../hooks/useCloud";

// ItemCard displays individual item details
const ItemCard = ({ item, onEdit, onDelete, enActive }) => {
  const [viewitem, setViewItem] = useState({});
  const [openModelForView, setOpenModelForView] = useState(false);

  const outofStock = (value) => {
    item.status = !item.status;
    enActive(value);
  };

  const viewAll = (open, item) => {
    if (open) {
      setViewItem(Object.assign({}, item));
      setOpenModelForView(true);
    } else {
      setViewItem({});
      setOpenModelForView(false);
    }
  };

  return (
    <>
    {/* {console.log(item,".....item.....")
    } */}
      <tr
        key={item.code}
        className="border-b hover:bg-gray-50 transition-all h-5"
      >
        <td className="p-3 text-sm text-gray-700 flex items-center">
          <img
            src={item.image}
            alt={item.name}
            className="h-8 w-8 rounded-full mr-2"
          />
          {item.name}
        </td>

        <td className="text-sm text-gray-700 items-center">
          <strong>{item.amount}</strong>
        </td>

        <td className="p-3 text-sm text-gray-700 items-center">{item.acPrice}</td>

        <td className="p-3 text-sm text-gray-700 items-center">
          <span role="img" aria-label="spicy">🌶️</span>
          <span role="img" aria-label="veg" className="ml-2">🥗</span>
        </td>

        <td className="p-3 text-sm text-gray-700">{item.reating}</td>
        {console.log(item.status,".......")
        }

        <td
          className={`p-3 text-sm font-semibold cursor-pointer ${item.status ? "text-green-500" : "text-red-500"}`}
          onClick={() => outofStock(item.id)}
        >
          {item.status ? "In Stock" : "Out of Stock"}
        </td>

        <td className="p-3 text-sm text-gray-700 items-center space-x-2">
          <button
            onClick={() => onEdit(item)}
            className="text-blue-500 hover:underline"
          >
            ✏️
          </button>
          <button
            onClick={() =>{
              const pro = prompt("Are you sure ? you are deleteing ",item.name);
              if(pro!=null)onDelete(item.id)
              }}
            className="text-red-500 hover:underline"
          >
            🗑️
          </button>
          <button
            onClick={() => viewAll(true, item)}
            className="text-red-500 hover:underline"
          >
            👀
          </button>
        </td>
      </tr>

      {/* Modal */}
      {openModelForView && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-1/2">
            <button
              className="text-red-500 float-right"
              onClick={() => viewAll(false, {})}
            >
              ✖
            </button>
            <h2 className="text-lg font-bold">{viewitem.name}</h2>
            <img
              src={viewitem.image}
              alt={viewitem.name}
              className="h-32 w-32 rounded-full mx-auto my-4"
            />
            <p><strong>Category:</strong> {viewitem.category}</p>
            <p><strong>Description:</strong> {viewitem.description}</p>
            <p><strong>Price:</strong> ₹{viewitem.amount}</p>
            <p><strong>Rating:</strong> ⭐{viewitem.reating}</p>
            <p><strong>Sub-Category:</strong> {viewitem.subCategoryName}</p>
            <p><strong>GST:</strong> {viewitem.gst}%</p>
            <p><strong>Packaging Charge:</strong> ₹{viewitem.pkgCharge}</p>
            <h3 className="font-bold mt-4">Variants:</h3>
            <ul className="list-disc ml-5">
              {viewitem.variant?.length ? (
                viewitem.variant.map((variant) => (
                  <li key={variant.id}>
                    {variant.varientName} - ₹{variant.varientAmount}
                  </li>
                ))
              ) : (
                <li>No Variants Available</li>
              )}
            </ul>
            <h3 className="font-bold mt-4">Add-Ons:</h3>
            <ul className="list-disc ml-5">
              {viewitem.addOns?.length ? (
                viewitem.addOns.map((addOn) => (
                  <li key={addOn.id}>
                    {addOn.name} - ₹{addOn.price}
                  </li>
                ))
              ) : (
                <li>No Add-Ons Available</li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};



// EditItemForm allows for editing item details

const EditItemForm = ({ item, categories, onSave, onCancel }) => {

  
  
  


  const [formData, setFormData] = useState({
    ...item,
    categoryId: item.categoryId || "",
    price: item.amount || "",
    halfPrice: item.halfPrice || "",
    acPrice: item.acPrice || "",
    itmNo: item.itmNo || "",
    rating: item.rating || "",
    imgUrl: item.imgUrl || "",
    varient: item.varient || { status: false, items: [] },
    isAddOns: item.isAddOns || { status: false, items: [] },
    nonveg: item.nonveg,
    veg: item.veg,
    rating: item.reating


  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVariationChange = (index, field, value) => {
    const newVariants = [...formData.varient.items];
    newVariants[index][field] = value;
    setFormData({
      ...formData,
      varient: { ...formData.varient, items: newVariants },
    });
  };

  const handleAddOnsChange = (index, field, value) => {
    const newAddOns = [...formData.isAddOns.items];
    newAddOns[index][field] = value;
    setFormData({
      ...formData,
      isAddOns: { ...formData.isAddOns, items: newAddOns },
    });
  };

  const handleAddVariation = () => {
    setFormData({
      ...formData,
      varient: {
        ...formData.varient,
        status: true,
        items: [...formData.varient.items, { name: "", amount: "", acAmount: "" }],
      },
    });
  };
  const handleAddOns = () => {
    setFormData({
      ...formData,
      isAddOns: {
        ...formData.isAddOns,
        status: true,
        items: [...formData.isAddOns.items, { name: "", unit: "", amount: "", acAmount: "" }],
      },
    });

  }

  const handleRemoveVariation = (index) => {
    const newVariants = formData.varient.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      varient: {
        ...formData.varient,
        items: newVariants,
      },
    });
  };

  const handleRemoveAddOns = (index) => {
    const newVariants = formData.isAddOns.items.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      isAddOns: {
        ...formData.isAddOns,
        items: newVariants,
      },
    });
  };
  const [desableSave, setDisableSave] = useState(false);
  const [selectedFoodTypes, setSelectedFoodTypes] = useState([]);

  const foodTypes = [
    { label: "Veg", value: "veg", icon: "🟢" },
    { label: "Non veg", value: "nonVeg", icon: "🔺" },
    { label: "Spicy", value: "spicy", icon: "🌶️" },
    { label: "Chef's special", value: "chefSpecial", icon: "👨‍🍳" },
    { label: "Bestseller", value: "bestseller", icon: "🏆" },
  ];

  const toggleFoodType = (type) => {
    console.log(selectedFoodTypes);


    setSelectedFoodTypes((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type) // Remove if already selected
        : [...prev, type] // Add if not selected
    );
  };

  const handleSave = async () => {

    setDisableSave(true);

    const obj = {
      categoryId: formData.categoryId,
      name: formData.name,
      imgUrl: formData.imgUrl,
      itmNo: formData.itmNo,
      description: formData.description,
      price: formData.price,
      veg: selectedFoodTypes.includes("veg"),
      nonveg: selectedFoodTypes.includes("nonVeg"),
      spicy: selectedFoodTypes.includes("spicy"),
      chefspe: selectedFoodTypes.includes("chefSpecial"),
      bestSellar: selectedFoodTypes.includes("bestseller"),
      rating: formData.rating,
      halfPrice: "",
      acPrice: formData.acPrice,
      varient: formData.varient,
      isAddOns: formData.isAddOns
    };
    if (!formData.categoryId || !formData.price || !formData.name) {
      alert("Please Select right info!");
      return false;
    } else {

      if (formData.id) {
        console.log(formData.id, "..... data");

        const ans = await updateITem(obj, formData.id);
        if (ans.data.event) {
          onSave(formData);

          // Optionally, refresh or provide user feedback
        }
      } else {
        const ans = await addItemAPI(obj);
        if (ans.data.event) {
          onSave(formData);
          // Optionally, refresh or provide user feedback
        }
      }
    }
  };
  useEffect(()=>{

    if(item.categoryId){
      // setSelectedFoodTypes()
      // console.log(item);
      if(item.veg){
        toggleFoodType("veg");
      }
      if(item.bestSellar
      ){
        toggleFoodType("bestseller");
      }
      if(item.chefspe){
        toggleFoodType("chefSpecial");
      }
      if(item.nonveg){
        toggleFoodType("nonVeg");
      }
      if(item.specify){
        toggleFoodType("spicy");
      }
      
    }
  },[])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-100  ml-20 mt-5">
      <div className="bg-white p-8 rounded shadow-lg max-w-screen-lg w-full  h-[90vh] overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">Add & Edit Item</h2>
        <form>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-2">
              <label className="block text-sm font-medium">Item Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium">Category</label>
              <select
                name="categoryId"
                value={formData.categoryId}
                onChange={handleChange}
                className="border p-1 w-full"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={`ttm_${category.id}`} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium">Image URL</label>
              <input
                type="text"
                name="imgUrl"
                value={formData.imgUrl}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium">Description</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium">
                Identification Number
              </label>
              <input
                type="text"
                name="itmNo"
                value={formData.itmNo}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-medium">Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>

            <div className="mb-2">
              <label className="block text-sm font-medium">AC Price</label>
              <input
                type="number"
                name="acPrice"
                value={formData.acPrice}
                onChange={handleChange}
                className="border p-1 w-full"
              />
            </div>
            <div> <label className="block text-sm font-medium">Rating</label>
              <input
                type="number"
                name="rating"
                value={formData.rating}
                onChange={handleChange}
                className="border p-1 w-full"
              /> </div>
            <div>
              <label className="block text-sm font-medium">Select Food Type</label>
              {/* <p className="text-sm text-gray-500 mb-2">
        (You can select multiple options. These will be reflected as filters for your diners.)
      </p> */}
              <div className="flex gap-1 flex-wrap">
                {foodTypes.map((food) => (
                  <button
                    key={food.value}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleFoodType(food.value)
                    }}
                    className={`px-3 py-1 rounded border ${selectedFoodTypes.includes(food.value)
                      ? "bg-green-200 border-green-600 text-green-900 font-bold"
                      : "bg-gray-100 border-gray-300 text-gray-700"
                      } transition-colors duration-300`}
                  >
                    {food.icon} {food.label}
                  </button>
                ))}
              </div>

              {/* <div className="mt-4">
        <p className="font-medium">Selected Food Types:</p>
        <p className="text-sm text-gray-700">
          {selectedFoodTypes.length > 0
            ? selectedFoodTypes.map(
                (type) =>
                  foodTypes.find((food) => food.value === type)?.label || type
              ).join(", ")
            : "None selected"}
        </p>
      </div> */}
            </div>
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-lg font-bold mb-2">Variations</h3>
              {console.log(item.variant)
              }
              {
              (item.variant && item.variant.length>0) && item.variant.map((variation, index) => (
                <div key={index} className="mb-2 flex items-center">
                  <input
                    type="text"
                    placeholder="Variation Name"
                    value={variation.varientName}
                    disabled
                    // onChange={(e) =>
                    //   handleVariationChange(index, "name", e.target.value)
                    // }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    disabled
                    value={variation.varientAmount}
                    // onChange={(e) =>
                    //   handleVariationChange(index, "amount", e.target.value)
                    // }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Ac Price"
                    disabled
                    value={variation.varientAcAmount}
                    // onChange={(e) =>
                    //   handleVariationChange(index, "acAmount", e.target.value)
                    // }
                    className="border p-1 w-full mr-1"
                  />
                  {/* <button
                    type="button"
                    onClick={() => handleRemoveVariation(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button> */}
                </div>
              ))}
              {formData.varient.items.map((variation, index) => (
                <div key={index} className="mb-2 flex items-center">
                  <input
                    type="text"
                    placeholder="Variation Name"
                    value={variation.name}
                    onChange={(e) =>
                      handleVariationChange(index, "name", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    value={variation.amount}
                    onChange={(e) =>
                      handleVariationChange(index, "amount", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Ac Price"
                    value={variation.acAmount}
                    onChange={(e) =>
                      handleVariationChange(index, "acAmount", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveVariation(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddVariation}
                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
              >
                Add Variation
              </button>
            </div>
            <br />
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-lg font-bold mb-2">Add Ons </h3>
              {formData?.isAddOns1?.items?.map((addOns, index) => (
                <div key={index} className="mb-2 flex items-center">
                  <input
                    type="text"
                    placeholder="AddOns  Name"
                    value={addOns.name}
                    onChange={(e) =>
                      handleAddOnsChange(index, "name", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="text"
                    placeholder="Units"
                    value={addOns.unit}
                    onChange={(e) =>
                      handleAddOnsChange(index, "unit", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Price"
                    value={addOns.amount}
                    onChange={(e) =>
                      handleAddOnsChange(index, "amount", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <input
                    type="number"
                    placeholder="Ac Price"
                    value={addOns.acAmount}
                    onChange={(e) =>
                      handleAddOnsChange(index, "acAmount", e.target.value)
                    }
                    className="border p-1 w-full mr-1"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveAddOns(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddOns}
                className="bg-green-500 text-white px-4 py-2 rounded mt-2"
              >
                Add  addOns
              </button>
            </div>
          </div>
          <div className="flex justify-end mt-3">
            <button
              type="button"
              disabled={desableSave}
              onClick={handleSave}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
const CategoriesModelEdit = ({ category, setModalOpen }) => {
  const navigate = useNavigate();
  const { categoryUpdate } = useCloud();

  const [form, setForm] = useState({
    name: category?.name || "",
    id: category?.id || "",
    url: category?.url || "",
});
  useEffect(()=>{
    setForm({
      name: category?.name || "",
      id: category?.id || "",
      url: category?.url || "",
    })
  },[])

  // Function to update the category
  const saveUpdateCategory = async () => {
    try {
      const res = await categoryUpdate({
        name: form.name,
        categoryId: form.id,
        url: form.url,
      });

      if (res) {
        setModalOpen(false);
        alert("Success! Category updated.");
        // window.location.reload()
        navigate("/table-overview");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      alert("Failed to update category.");
    }
  };

  // Function to update form values dynamically
  const handleInputChange = (field, value) => {
    setForm((prevForm) => ({ ...prevForm, [field]: value }));
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-96 shadow-lg">
        <h2 className="text-lg font-semibold mb-4 text-gray-700">
          Edit Category
        </h2>

        {/* Input for Category Name */}
        <input
          type="text"
          value={form.name}
          disabled
          onChange={(e) => handleInputChange("name", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter new category name"
        />

        {/* Input for Image URL */}
        <input
          type="text"
          value={form.url}
          onChange={(e) => handleInputChange("url", e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter image URL"
        />

        {/* Action Buttons */}
        <div className="flex justify-end gap-4">
          <button
            className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300"
            onClick={() => setModalOpen(false)}
          >
            Close
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            onClick={saveUpdateCategory}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};


// AddNewCategory allows adding a new category
const AddNewCategory = ({ onSave, onCancel }) => {

  const [formData, setFormData] = useState({
    category: "",
    type: "",
    associatedItems: [],
  });
  const [newItem, setNewItem] = useState({ name: "", amount: "" });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handleAddItem = () => {
    if (newItem.name && newItem.amount) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        associatedItems: [
          ...prevFormData.associatedItems,
          { id: Date.now(), name: newItem.name, amount: newItem.amount },
        ],
      }));
      setNewItem({ name: "", amount: "" });
    }
  };

  const handleRemoveItem = (itemId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      associatedItems: prevFormData.associatedItems.filter(
        (item) => item.id !== itemId
      ),
    }));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Add Category</h2>
        <form>
          <div className="mb-4">
            <label className="block text-sm font-medium">Category Name</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Image Link</label>
            <input
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium">Type</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              className="border p-2 w-full"
            >
              <option value="">Select Type</option>
              <option value="Normal">Normal</option>
              <option value="VAT">VAT</option>
            </select>
          </div>

          {formData.type === "false" && (
            <div className="mb-4">
              <label className="block text-sm font-medium">Add Items</label>
              <div className="mb-4 flex space-x-2">
                <input
                  type="text"
                  name="name"
                  value={newItem.name}
                  onChange={handleItemChange}
                  placeholder="Item Name"
                  className="border p-1 w-30"
                />
                <input
                  type="number"
                  name="amount"
                  value={newItem.amount}
                  onChange={handleItemChange}
                  placeholder="Amount"
                  className="border p-1 w-30"
                />
                <button
                  type="button"
                  onClick={handleAddItem}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  + Add Item
                </button>
              </div>

              <div className="border p-2">
                {formData &&
                  formData.associatedItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center mb-2"
                    >
                      <div>
                        <span>{item.name}</span> - <span>RS{item.amount}</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveItem(item.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          )}

          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onSave(formData)}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// ItemManagement is the main component managing items and categories
const ItemManagement = () => {
  const [items, setItems] = useState([
    // Add more items here...
  ]);
  useEffect(() => {
    const fetch = async () => {
      const items = store.items;
      // console.log(...item.data.menuItems);
      setItems(items);
    };
    fetch();
  }, []);

  const [editingItem, setEditingItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const [categories, setCategories] = useState([{ name: "All", id: 0 }]);
  const maxVisibleCategories = 4;
  const navigate = useNavigate();

  // const categories = ['All', 'Main Course', 'Appetizer', 'Dessert', 'Beverage'];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const allCategories = store.categories; // Assuming allCatrgories is your API call function
        // console.log(allc);
        setCategories(() => [{ name: "All", id: 0 }, ...allCategories]);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  const handleEdit = (item) => {
    // console.log(item, "...........");


    setEditingItem(item);

  };

  const handleSave = (updatedItem) => {
    setItems(
      items.map((item) => (item.id === updatedItem.id ? updatedItem : item))
    );
    setEditingItem(null);
  };

  const handleDelete = (id) => {
    setItems(items.filter((item) => item.id !== id));

    // /admin/deleteMenu
    deletItem(id);
    // console.log(id,"......................");

    //
  };

  const handleCancel = () => {
    setEditingItem(null);
  };

  const handleSaveCategory = async (newCategory) => {
    // Handle saving new category

    const obj = {
      name: newCategory.category,
      typeHalf: newCategory.type,
      items: newCategory.associatedItems,
    };

    // console.log('Category saved:', newCategory);
    if (newCategory.category && newCategory.type) {
      const ans = await addCategoryAPI(obj);
      // console.log(ans.data.event);

      if (ans.data.event) {
        setAddCategory(false);
        window.location.reload();
      }
    }
  };

  const handleCancelCategory = () => {
    setAddCategory(false);
  };

  const filteredItems = items.filter(
    (item) =>
      (selectedCategory === "All" || item.category === selectedCategory) &&
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleDropdownChange = (e) => {
    setSelectedCategory(e.target.value);
    setSearchTerm("");
  };
  const enActive = async (value) => {
    // call incative api
    const status = await outOfStock({ itemid: value });
    if (status.data.event) {
      alert("Done");
      // nav
      // navigate("/table-overview");
      setTimeout(() => {

        navigate("/menu-managment");
      }, 100);
    }

    // /menu/outofstock
  };
  const [isEditCategory, setEditCategory] = useState(null)

  const handleEditClick = async (category) => {
    setEditCategory(category);
    console.log(category, "....");


  }
  return (
    <div className="p-8">
      <div className="flex justify-between mb-6">
        <div className="flex justify-between mb-4">

          <input
            type="text"
            placeholder="Search items..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border p-2 rounded"
          />
        </div>
        <div className="space-x-4">
          <button
            onClick={() =>
              setEditingItem({
                id: null,
                name: "",
                category: "",
                description: "",
                amount: 0,
                image: "",
              })
            }
            className="bg-black text-white px-4 py-2 rounded mr-2"
          >
            + Add Item
          </button>
          {/* <button
            // onClick={() => setAddCategory(true)}
            className="bg-black text-white px-4 mr-2 py-2 rounded"
          >
            + Add Sub-Category
          </button> */}
          <button
            onClick={() => setAddCategory(true)}
            className="bg-black text-white px-4 py-2 rounded"
          >
            + Add Category
          </button>
        </div>
      </div>



      <div className="flex  gap-y-5 gap-x-2">
        <div className="p-1 w-1/6 max-w-[16.6667%]">
          <h2 className="text-lg font-semibold mb-4">Menu</h2>
          <div className="space-y-2">
            {categories &&
              categories.map((category, index) => {

                // Render categories as buttons for the visible items
                return (
                  <div
                    key={category.id}
                    className={`w-full flex items-center px-5 py-2 border rounded-lg ${selectedCategory === category.name
                      ? "border-green-500 text-green-700"
                      : "border-gray-300 text-gray-700"
                      }`}
                    onClick={() => setSelectedCategory(category.name)}
                  >
                    {/* Left section: Current function */}
                    <span className="flex-grow text-left cursor-pointer">{category.name}</span>

                    {/* Right section: Edit button */}
                    {index != 0 && <button
                      onClick={() => handleEditClick(category)}
                      className="text-sm text-blue-500 underline hover:text-blue-700 focus:outline-none ml-4"
                    >
                      ✏️
                    </button>}
                  </div>
                );

              })}
            {(isEditCategory && isEditCategory?.name) && <CategoriesModelEdit category={isEditCategory} setModalOpen={setEditCategory} />} 
            {/* {categories && categories.length > maxVisibleCategories && (
          // Dropdown for extra categories
          <div className="relative">
            <select
              onChange={handleDropdownChange}
              value={selectedCategory}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700"
            >
              <option value="">More Categories</option>
              {categories.slice(maxVisibleCategories).map((dropdownCategory) => (
                <option
                  key={`sl_${dropdownCategory.id}`}
                  value={dropdownCategory.name}
                >
                  {dropdownCategory.name}
                </option>
              ))}
            </select>
          </div>
        )} */}
          </div>
        </div>
        <div className="min-w-[83.067%] max-w-[83.067%]">
          <table className="w-[100%] bg-white border border-gray-200 rounded-lg">
            <thead className="bg-gray-100 border-b">
              <tr>
                {/* <th className="p-3 text-sm font-semibold text-left text-gray-700">
              <input type="checkbox" />
            </th>
            <th className="p-3 text-sm font-semibold text-left text-gray-700">
              Code
            </th> */}
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Name
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Price
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  AC Price
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Type
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Ratings
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Status
                </th>
                <th className="p-3 text-sm font-semibold text-left text-gray-700">
                  Edit/Delete
                </th>
              </tr>
            </thead>
            <tbody className="">
              {filteredItems.length > 0 ? filteredItems.map((item) => (
                <ItemCard
                  key={item.id}
                  item={item}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  enActive={enActive}
                />
              )) : (
                <tr>
                  <td
                    colSpan="9"
                    className="p-3 text-center text-gray-500 text-sm"
                  >
                    No items found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {editingItem && (
        <EditItemForm
          item={editingItem}
          onSave={handleSave}
          onCancel={handleCancel}
          categories={categories}
        />
      )}
      {addCategory && (
        <AddNewCategory
          onSave={handleSaveCategory}
          onCancel={handleCancelCategory}
        />
      )}
    </div>
  );
};

export default observer(ItemManagement);
