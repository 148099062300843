export const API_ENDPOINTS = {
  ADD_CATEGORY: "restaurant/admin/category",
  CATEGORY_UPDATE: "restaurant/admin/category", //put
  ALL_CATEGORIES: "restaurant/admin/category",
  DELETE_ITEM: (id) => `restaurant/admin/deleteMenu?id=${id}`,
  ADD_ITEM: "restaurant/admin/createMenu",
  UPDATE_ITEM: (id) => `restaurant/admin/createMenu?id=${id}`,
  GET_ALL_ITEMS: "restaurant/admin/createMenu",
  OUT_OF_STOCK: "restaurant/menu/outofstock",
  TABLE_DATA: "restaurant/get/room/table",
  GET_TABLE_DATA: "restaurant/admin/printedKOT",
  REQUESTED_KOT_DATA: "restaurant/admin/RequestKOT",
  CHANGE_KOT_STATUS: "restaurant/afterKotPrintchange/Status",
  ORDER_DONE: "restaurant/order/done",
  CREATE_ORDER_BY_RESTAURANT: "restaurant/order/create/restarunt",
  EXTRA_AMOUNT_ADDED: "restaurant/order/addExtracharge",
  EDIT_EXISTING_ORDER: "restaurant/order/update/addnewitem",
  PAYMENT_METHOD: "restaurant/order/done/paymentmethod",
  ORDER_REJECT: "restaurant/order/cancle/reject/digitalOrder",
  CHANGE_QUANTITY_OF_ITEM: "restaurant/order/change/quentity",
  GET_PUBLIC_ITEM_BY_VPA: (vpa) => `restaurant/public/user/Item?vpa=${vpa}`,
  GET_WAITER_LIST: "restaurant/waiter/allwaiter",
  GET_ALL_CATEGORY_AND_ITEM: "restaurant/category/menu/varient/getfororder",
  SYNC_CLOUD: "restaurant/order/report/set/end?",
  ORDER_DETAILS: "/anylisis/orders/report",
  GET_DIGITAL_ORDER: "restaurant/admin/RequestKOT",
  UPDATE_GST: "restaurant/gst/updateGST",

  GET_ITEM_WISE_SALE: "anylisis/items/wise/reportfor/last30days",
  GET_RESTAURANT_INFO: "restaurant/get/info",
  GET_BILL_PRINT: "/anylisis/order/history/printbill/history",
  REGISTER_NOTIFICATION_TOKEN: "restaurant/notification/register",
  TEST_NOTIFICATION: "restaurant/notification/test",
  CHANGE_STATUS_WAITER:"/waiter/waiter/newwaiter",
  ADD_NEW_WAITER:"/waiter/waiter/newwaiter",
  PARTNER_API_INTEGRATION:"/restaurant/partner/swiggy/zomato",
};
