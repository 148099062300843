import React from 'react'
import { Outlet } from 'react-router-dom'
import SettingHeader from './SettingHeader'

const SettingHeading = () => {
  return (

    <>
    <SettingHeader/>
{/* add subheading here  */}
    
    {/* <div>SettingHeading</div> */}
    <Outlet />
    </>
  )
}

export default SettingHeading