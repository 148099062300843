import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../Environments/Environment.Test";
import { API_ENDPOINTS } from "../api/index";
import { data } from "autoprefixer";
import useOrder from "./useOrder";
import store from "../store";
const BASE_URL = `${api}/`; // Define your default base URL here

export const useCloud = () => {
  const { syncBillNoWithCloudBillNo } = useOrder();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const storedToken = await localStorage.getItem("authToken");
      setToken(storedToken);
    };
    fetchToken();
  }, []);

  const apiInstance = axios.create({
    baseURL: BASE_URL,
  });

  // Set up Axios interceptors
  useEffect(() => {
    apiInstance.interceptors.request.use(
      async (config) => {
        const token = await localStorage.getItem("authToken");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, [token, apiInstance]);

  const addCategoryAPI = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.ADD_CATEGORY,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const allCategories = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.ALL_CATEGORIES);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const deleteItem = async (id) => {
    try {
      const res = await apiInstance.delete(API_ENDPOINTS.DELETE_ITEM(id));
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const addItemAPI = async (requestBody) => {
    try {
      const res = await apiInstance.post(API_ENDPOINTS.ADD_ITEM, requestBody);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const updateItem = async (requestBody, id) => {
    try {
      const res = await apiInstance.put(
        API_ENDPOINTS.UPDATE_ITEM(id),
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getAllItems = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.GET_ALL_ITEMS);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const outOfStock = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.OUT_OF_STOCK,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const tableData = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.TABLE_DATA);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getTableData = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.GET_TABLE_DATA);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const requestedKOTData = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.REQUESTED_KOT_DATA);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const changeKOTStatus = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.CHANGE_KOT_STATUS,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const orderDone = async (requestBody) => {
    try {
      const res = await apiInstance.post(API_ENDPOINTS.ORDER_DONE, requestBody);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const createOrderByRestarunt = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.CREATE_ORDER_BY_RESTAURANT,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const extraAmountAdded = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.EXTRA_AMOUNT_ADDED,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const editExistingOrder = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.EDIT_EXISTING_ORDER,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const paymentMethodAPI = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.PAYMENT_METHOD,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const orderReject = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.ORDER_REJECT,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const changeQuentityOfItem = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.CHANGE_QUANTITY_OF_ITEM,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getPublicItemBYVPA = async (vpa) => {
    try {
      const res = await apiInstance.get(
        API_ENDPOINTS.GET_PUBLIC_ITEM_BY_VPA(vpa)
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getWaiterList = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.GET_WAITER_LIST);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getAllCategoryAndItem = async () => {
    try {
      const res = await apiInstance.get(
        API_ENDPOINTS.GET_ALL_CATEGORY_AND_ITEM
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getRestaurantInfo = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.GET_RESTAURANT_INFO);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const syncCloud = async (orders) => {
    if (!navigator.onLine) {
      console.log("No internet connection");
      return;
    }
    const restaurantInfo = await getRestaurantInfo();
    let lastBillNo = Number(restaurantInfo.data.lastbillNo);
    store.setLastBillNo(lastBillNo);
    const transformOrders = await syncBillNoWithCloudBillNo(lastBillNo);

    const convertTimeToSQLTime = (_date) => {
      const date = new Date(_date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const processedOrders = transformOrders.map((order) => {
      store.setLastBillNo(order.billNo);
      return {
        billNo: order.billNo,
        name: order.username,
        mobile: order.usermobile,
        tableVPA: order.tableVPA,
        amount: order.amount,
        isCancle: order.status === "BILLCancelled",
        items: order.printKOT.items.map((item) => ({
          id: item.itemId,
          quantity: parseInt(item.quantity),
          name: item.name,
          price: item.price,
        })),
        paymentmethod: order?.payment?.methods || [
          { name: "Cash", amount: order.amount },
        ],
        waiterid: order.assignedWaiter,
        discount:
          parseFloat(order.discount) + parseFloat(order.extraDiscount) || 0,
        extraitem: order.extraOrders.map((item) => ({
          quantity: parseInt(item.qty),
          name: item.name,
          price: item.price,
        })),
        createdAt: convertTimeToSQLTime(order.createdAt),
        updatedAt: convertTimeToSQLTime(order.updatedAt),
        tips: order.tips,
        onlineCodes: order.onlineCodes,
      };
    });

    const payload = {
      data: processedOrders,
    };

    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await apiInstance.post(
          API_ENDPOINTS.SYNC_CLOUD,
          payload
        );
        if (data.event) {
          resolve(true);
        } else {
          reject(false);
        }
      } catch (err) {
        reject(err);
      }
    });

    return true;
  };

  const getOrderHistory = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.ORDER_DETAILS);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const getDigitalOrders = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.GET_DIGITAL_ORDER);
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  const gstUpdate = async (orders) => {
    const payload = {
      ...orders,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await apiInstance.post(
          API_ENDPOINTS.UPDATE_GST,
          payload
        );
        if (data.event) {
          resolve(true);
        } else {
          reject(false);
        }
      } catch (err) {
        reject(err);
      }
    });

    return true;
  };

  const categoryUpdate = async (orders) => {
    const payload = {
      ...orders,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await apiInstance.put(
          API_ENDPOINTS.CATEGORY_UPDATE,
          payload
        );
        if (data.event) {
          resolve(true);
        } else {
          reject(false);
        }
      } catch (err) {
        reject(err);
      }
    });

    return true;
  };

  const itemWiseSale = async () => {
    try {
      const { data } = await apiInstance.get(API_ENDPOINTS.GET_ITEM_WISE_SALE);
      return data;
    } catch (err) {
      throw err;
    }
  };

  const getBillInfo = async (payload) => {
    try {
      const res = await apiInstance.post(API_ENDPOINTS.GET_BILL_PRINT, payload);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const registerNotificationToken = async (token) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.REGISTER_NOTIFICATION_TOKEN,
        { token }
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const testNotification = async () => {
    try {
      const res = await apiInstance.get(API_ENDPOINTS.TEST_NOTIFICATION);
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const changeStatusWaiter = async (requestBody) => {
    try {
      const res = await apiInstance.put(
        API_ENDPOINTS.CHANGE_STATUS_WAITER,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  const addNewWaiter = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.ADD_NEW_WAITER,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const partnerAPIIngegration = async (requestBody) => {
    try {
      const res = await apiInstance.post(
        API_ENDPOINTS.PARTNER_API_INTEGRATION,
        requestBody
      );
      return res.data;
    } catch (err) {
      throw err;
    }
  };
  return {
    addCategoryAPI,
    allCategories,
    deleteItem,
    addItemAPI,
    updateItem,
    getAllItems,
    outOfStock,
    tableData,
    getTableData,
    requestedKOTData,
    changeKOTStatus,
    orderDone,
    createOrderByRestarunt,
    extraAmountAdded,
    editExistingOrder,
    paymentMethodAPI,
    orderReject,
    changeQuentityOfItem,
    getPublicItemBYVPA,
    getWaiterList,
    getAllCategoryAndItem,
    syncCloud,
    getOrderHistory,
    getDigitalOrders,
    gstUpdate,
    categoryUpdate,
    itemWiseSale,
    getRestaurantInfo,
    getBillInfo,
    registerNotificationToken,
    testNotification,
    changeStatusWaiter,
    addNewWaiter,
    partnerAPIIngegration,
  };
};
