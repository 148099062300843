import { useEffect, useState } from "react";
import { ZOMATO } from "../LocalServicesDyno/LocalApiStore";
import { printKotForPartner } from "./printoption/PartnerPintKot";
import { printBillPartner } from "./printoption/PartnerBillPrint";

export const OrderDetailsModal = ({ isOpen, onClose, data, btns, zomatoid }) => {
  const { acceptNewOrder, rejectOrder, markOrderReady } = ZOMATO();
  const [timeDifference, setTimeDifference] = useState("00:00:00");

  // Calculate time difference
  useEffect(() => {
    if (!data?.order?.createdAt) return;

    const updateDifference = () => {
      const orderTime = new Date(data.order.createdAt);
      const currentTime = new Date();
      const diffInSeconds = Math.floor((currentTime - orderTime) / 1000);

      const hours = String(Math.floor(diffInSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((diffInSeconds % 3600) / 60)).padStart(2, "0");
      const seconds = String(diffInSeconds % 60).padStart(2, "0");

      setTimeDifference(`${hours}:${minutes}:${seconds}`);
    };

    updateDifference();
    const intervalId = setInterval(updateDifference, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [data?.order?.createdAt]);

  if (!isOpen) return null;

  const accept = async (order_id, delivery_time, items) => {
    delivery_time = 30;
    const response = await acceptNewOrder({ order_id, delivery_time });

    if (response.status === "success") {
      printKotForPartner({ items, orderId: order_id });
      onClose();
    }
  };

  const reject = async (order_id, restaurant_id) => {
    const response = await rejectOrder({ order_id, restaurant_id });
    if (response.status === "success") {
      alert("Success");
      onClose();
    }
  };

  const markReady = async (order_id) => {
    const response = await markOrderReady({ order_id });
    if (response.status === "success") {
      alert("Success");
      onClose();
    }
  };

  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      <div className="bg-white w-3/4 max-w-full p-6 rounded-lg shadow-lg relative">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold">Order Details</h2>
          <button onClick={onClose} className="text-red-500 font-bold text-lg">
            ×
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="mt-4 mb-16">
          <h3 className="text-lg font-semibold mb-2">Order Information</h3>
          <p>
            <strong>Order ID:</strong> {data.order.id}
          </p>
          <p>
            <strong>Time:</strong> {data.order.createdAt}
          </p>
          <p>
            <strong>Restaurant ID:</strong> {data.order.resId}
          </p>
          <p>
            <strong>State:</strong> {data.order.state}
          </p>
          <p>
            <strong>Payment Method:</strong> {data.order.paymentMethod}
          </p>
          <p>
            <strong>Delivery Mode:</strong> {data.order.deliveryMode}
          </p>
          <p>
            <strong>OTP:</strong> {data.order.otp}
          </p>
        </div>
<div>
        <h3 className="text-lg font-semibold mt-4 mb-2">Items Ordered</h3>
          {data.order.cartDetails.items.dishes.map((item) =>
            item.customisations ? (
              <>
                {item.customisations.map((itm) => (
                  <div key={item.id} className="mb-2">
                    <p>
                      <strong>Item Name:</strong> {item.name} - <strong><i>{itm.name}</i></strong>
                    </p>
                    <p>
                      <strong>Quantity:</strong> {itm.quantity}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <div key={item.id} className="mb-2">
                <p>
                  <strong>Item Name:</strong> {item.name}
                </p>
                <p>
                  <strong>Quantity:</strong> {item.quantity}
                </p>
                <p>
                  <strong>Total Cost:</strong> ₹{item.totalCost}
                </p>
              </div>
            )
          )}

          <h3 className="text-lg font-semibold mt-4 mb-2">Total Charges</h3>
          <p>
            <strong>Subtotal:</strong> ₹{data.order.cartDetails.subtotal.amountDetails.totalCost}
          </p>
          <p>
            <strong>Total Bill:</strong> ₹{data.order.cartDetails.total.amountDetails.totalCost}
          </p>
        </div>

       

        {/* Fixed Buttons */}
        <div className="fixed bottom-0 left-5 right-0 bg-white border-t p-4 pl-[10%] flex justify-around">
          {btns.reject && (
            <button
              onClick={() => reject(data.order.id, zomatoid)}
              className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
            >
              Reject Order
            </button>
          )}
          {btns.printkot && (
            <button
              onClick={() =>
                printKotForPartner({ items: data.order.cartDetails.items.dishes, orderId: data.order.id })
              }
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
            >
              Print KOT
            </button>
          )}
          {btns.accept && (
            <button
              onClick={() =>
                accept(data.order.id, "30", data.order.cartDetails.items.dishes)
              }
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
            >
              Accept & Print KOT (Elapsed: {timeDifference})
            </button>
          )}
          {btns.ready && (
            <button
              onClick={() => markReady(data.order.id)}
              className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
            >
              Ready (Elapsed: {timeDifference})
            </button>
          )}
          {btns.printbill && (
            <button
              onClick={() =>
                printBillPartner({
                  items: data.order.cartDetails.items.dishes,
                  subtotal: data.order.cartDetails.subtotal.amountDetails.totalCost,
                  total: data.order.cartDetails.total.amountDetails.totalCost,
                  orderId: data.order.id,
                })
              }
              className="bg-gray-500 text-white px-4 py-2 rounded shadow hover:bg-gray-600"
            >
              Print Bill
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
