import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import OrderListDiital from './Component/OrderList';


const Ordermain = () => {
     const [activeTab, setActiveTab] = useState('parcel');
        const [orderParcel, setOrderParcel] = useState([{orderNo:1},{orderNo:2},{orderNo:4}]);
      const navigate = useNavigate();
  return (
   <>
    <div className="flex border-b border-gray-300 mb-4">
      <button
          className={`py-2 px-4 text-lg font-semibold ${activeTab === 'dine-in' ? 'border-b-2 border-yellow-500 text-gray-800' : 'text-gray-600'}`}
          onClick={() => {setActiveTab('dine-in')
            navigate('/table-overview');
          }}
        >
          Dine In
        </button>
        <button
          className={`py-2 px-4 text-lg font-semibold ${activeTab === 'parcel' ? 'border-b-2 border-yellow-500 text-gray-800' : 'text-gray-600'}`}
          onClick={() =>{ setActiveTab('parcel')
            navigate('/order-parcel');
            
          }}
        >
          Online
        </button>
       
      </div>
      <OrderListDiital/>
      {/* order check status  */}
      </>
  )
}

export default Ordermain