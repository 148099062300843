export const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  if (!token) {
    return false;
  }

  const payload = JSON.parse(atob(token.split(".")[1]));
  const expirationTime = payload.exp * 1000;

  if (Date.now() >= expirationTime) {
    localStorage.removeItem("authToken");
    return false;
  }
  return true;
};


export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};