import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SignUpNew = () => {
  const [passwordVisible, setPasswordVisible] = useState(false); // For create password
  const [reenterPasswordVisible, setReenterPasswordVisible] = useState(false); // For re-enter password
  const [image, setImage] = useState(null); // To store uploaded image

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    adminName: "",
    imageURL: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file.name); // Set the image name
      handleInputChange("imageURL", file.name); // Add the image file name to formData
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate password and confirm password
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    // Save the data in localStorage
    const adminData = {
      name: formData.adminName,
      email: formData.email,
      password: formData.password,
      contact: formData.phoneNumber,
      imageURL: formData.imageURL,
    };

    localStorage.setItem("adminData", JSON.stringify(adminData));
    alert("Admin profile saved successfully!");
    navigate("/managerform");
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      {/* Left Section */}
      <div className="w-full md:w-1/4 bg-gradient-to-b from-[#16A34A] to-[#035722] text-white p-8">
        <h1 className="text-2xl font-bold mb-4">Step 1</h1>
        <p className="mb-8">Create admin's profile</p>
        <ul className="space-y-6">
          <li className="flex items-center">
            <div className="w-6 h-6 rounded-full bg-white text-green-600 flex items-center justify-center font-bold">
              1
            </div>
            <span className="ml-4">Admin detail</span>
          </li>
          <li className="flex items-center opacity-50">
            <div className="w-6 h-6 rounded-full border border-white flex items-center justify-center font-bold">
              2
            </div>
            <span className="ml-4">Manager's data</span>
          </li>
        </ul>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-3/4 bg-white p-8">
        <h2 className="text-2xl font-bold mb-4 underline">Sign up</h2>
        <p className="mb-8 text-gray-500">
          Already have an Account?{" "}
          <a href="/login" className="text-green-600 underline">
            Log in
          </a>
        </p>
        <form onSubmit={handleSubmit}>
          {/* First Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="adminName"
                className="block mb-1 text-gray-600 font-light"
              >
                Admin name
              </label>
              <input
                id="adminName"
                type="text"
                className="w-full p-2 border rounded"
                value={formData.adminName}
                onChange={(e) => handleInputChange("adminName", e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="imageUpload"
                className="block mb-1 text-gray-600 font-light"
              >
                Image URL or upload
              </label>
              <div className="flex gap-2 items-center">
                <input
                  id="imageUpload"
                  type="text"
                  className="flex-1 p-2 border rounded"
                  value={formData.imageURL}
                  onChange={(e) =>
                    handleInputChange("imageURL", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                htmlFor="phoneNumber"
                className="block mb-1 text-gray-600 font-light"
              >
                Phone number
              </label>
              <input
                id="phoneNumber"
                type="text"
                className="w-full p-2 border rounded"
                value={formData.phoneNumber}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
            </div>
            <div>
              <label
                htmlFor="emailID"
                className="block mb-1 text-gray-600 font-light"
              >
                Email ID
              </label>
              <input
                id="emailID"
                type="email"
                className="w-full p-2 border rounded"
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </div>
          </div>

          {/* Third Row */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="relative">
              <label
                htmlFor="createPassword"
                className="block mb-1 text-gray-600 font-light"
              >
                Create password
              </label>
              <input
                id="createPassword"
                type={passwordVisible ? "text" : "password"}
                className="w-full p-2 border rounded"
                value={formData.password}
                onChange={(e) => handleInputChange("password", e.target.value)}
              />
              <button
                type="button"
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="absolute top-1/2 right-2 transform text-gray-500"
              >
                <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
              </button>
            </div>
            <div className="relative">
              <label
                htmlFor="reenterPassword"
                className="block mb-1 text-gray-600 font-light"
              >
                Re-enter password
              </label>
              <input
                id="reenterPassword"
                type={reenterPasswordVisible ? "text" : "password"}
                className="w-full p-2 border rounded"
                value={formData.confirmPassword}
                onChange={(e) =>
                  handleInputChange("confirmPassword", e.target.value)
                }
              />
              <button
                type="button"
                onClick={() =>
                  setReenterPasswordVisible(!reenterPasswordVisible)
                }
                className="absolute top-1/2 right-2 transform text-gray-500"
              >
                <FontAwesomeIcon
                  icon={reenterPasswordVisible ? faEye : faEyeSlash}
                />
              </button>
            </div>
          </div>

          {/* Footer Buttons */}
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0 mt-16">
            <button
              type="button"
              className="px-4 py-2 bg-[#979797] rounded-full text-white font-extrabold hover:bg-[#b0b0b0] hover:text-white w-full md:w-auto"
            >
              Back
            </button>
            <button
              type="submit"
              className="px-6 py-2 bg-green-600 text-white font-extrabold rounded-full hover:bg-green-700 w-full md:w-auto"
            >
              NEXT STEP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpNew;
