import { SWIGGY, ZOMATO } from "./Component/PartnerOrder/LocalServicesDyno/LocalApiStore";
import orderNotificationSound from "./ringtons/zomato_ring_5.mp3";
import newRingNotification from "./ringtons/zomato_ring_3.mp3";

export const fetchDataAndHandleOrders = async () => {
  const { getCurrentOrders } = ZOMATO();
  const { getOrders } = SWIGGY();

  let currentOrders = [];
  let swiggyOrder = null;

  try {
    // Attempt to fetch Zomato orders
    currentOrders = await getCurrentOrders();
    console.log("Zomato Orders:", currentOrders);
  } catch (error) {
    console.error("Failed to fetch Zomato orders:", error);
  }

  try {
    // Attempt to fetch Swiggy orders
    swiggyOrder = await getOrders();
    console.log("Swiggy Orders:", swiggyOrder);
  } catch (error) {
    console.error("Failed to fetch Swiggy orders:", error);
  }

  // Process Zomato orders
  if (currentOrders?.[0]?.new_orders?.count > 0) {
    await playSound("Zomato");
    console.log("Notification sound played for Zomato orders.");
    return currentOrders[0].new_orders.count;
  }

  // Process Swiggy orders
  if (swiggyOrder?.restaurantData?.length > 0) {
    const restaurant = swiggyOrder.restaurantData[0];
    if (restaurant.orders?.length > 0) {
      for (const order of restaurant.orders) {
        if (order.status?.placed_status === "unplaced") {
          await playSound("Swiggy");
          console.log("Notification sound played for Swiggy unplaced orders.");
          return true;
        }
      }
    }
  }

  console.log("No new orders.");
  return false;
};

const audios = new Audio(newRingNotification);
const audioz = new Audio(orderNotificationSound);
export const playSound = async (soundFile) => {
  try {
    if(soundFile === "Zomato"){
      audios.volume = 1.0; // Set volume to maximum
      await audios.play();}
      else{
        audioz.volume = 1.0; // Set volume to maximum
        await audioz.play();
      }
// // Set volume to maximum
//     await audio.play();
  } catch (error) {
    console.error("Playback failed:", error);
  }
};
