import { useEffect } from "react";
import { useCloud } from "./useCloud";
import store from "../store/index"; // Adjust the path as necessary
import useOrder from "./useOrder";
import useNotifications from "./useNotifications";

export const useStore = () => {
  const { processOrder } = useOrder();
  const { registerToken } = useNotifications();
  const {
    getAllItems,
    allCategories,
    tableData,
    getTableData,
    requestedKOTData,
    getWaiterList,
    getAllCategoryAndItem,
    getOrderHistory,
    getDigitalOrders,
    getRestaurantInfo,
  } = useCloud();

  const transformDigitalOrders = (order) => {
    const formatedOrder = order.items.map((newOrder) => ({
      itemId: newOrder.itemId,
      quantity: parseInt(newOrder.quantity),
      varientid: newOrder.varientid || undefined,
    }));
    const rawOrder = order.items.map((newOrder) => ({
      itemId: newOrder.itemId,
      name: newOrder.name,
      price: newOrder.price || "0",
      variant: newOrder.variant || [],
      quantity: parseInt(newOrder.quantity),
      uniqueId:
        newOrder.itemId +
        (newOrder?.varientid ? `_${newOrder?.varientid}` : "") +
        "-online",
    }));
    return {
      rawOrder,
      formatedOrder,
      tableVPA: order.tableVPA,
      cancleOrders: [],
      extraOrders: [],
      username: order.name,
      usermobile: order?.mobileNo || "NA",
      orderStatus: "RequestKOT",
      onlineBillNo: order.onlineCode,
      replaceItems: "RequestKOT",
      isOnline: true,
    };
  };
  const getCloudSync = async () => {
    try {
      await registerToken();
      const { menuItems: items } = await getAllItems();
      const { categories } = await allCategories();
      const { data: rooms } = await tableData();
      const { kotPrintedItem: printedKOTs, vpaKotPrinted: printedKOT_VPA } =
        await getTableData();
      const { waiters = [] } = await getWaiterList();
      const { data: completeMenu } = await getAllCategoryAndItem();
      const { event, requestKOTAmoutntItem, requestKot } =
        await getDigitalOrders();
      const restaurantInfo = await getRestaurantInfo();
      const digitalOrders = requestKOTAmoutntItem.map(transformDigitalOrders);
      const digitalOrdersTables = requestKot.map((table) => table.tableVPA);

      console.log({ digitalOrders, digitalOrdersTables });

      // store initialization
      store.addItems(items);
      store.addCategories(categories);
      store.addRooms(rooms);
      store.addWaiters(waiters);
      store.updateCompleteMenu(completeMenu);
      store.setDigitalOrderVPA(digitalOrdersTables);
      store.setLastBillNo(Number(restaurantInfo.data.lastbillNo));

      setTimeout(() => {
        digitalOrders.map(async (order) => {
          await processOrder(order);
          const digitalOrderProcessed = new CustomEvent(
            "digitalOrderProcessed",
            {}
          );
          window.dispatchEvent(digitalOrderProcessed);
        });
      }, 1000);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  useEffect(() => {
    const fetchOnline = async () => {
      await getCloudSync();
    };
    fetchOnline();
  }, []);

  return { store, getCloudSync };
};

export default useStore;
