import React from 'react'
import { NavLink } from 'react-router-dom'

const SettingHeader = () => {
  return (
    <header className="top-0 left-0 right-0 w-full bg-white border-b border-[#D9D9D9] z-20">
      <div className="container mx-auto flex justify-between items-center p-2 md:p-4 h-[80px]">
        {/* Left Side (Title or Logo) */}
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold text-gray-800">SETTINGS</h1>
        </div>

        {/* Right Side (Navigation Tabs) */}
        <div className="flex items-center gap-6">
          {/* GST Tab */}
          <NavLink
            to="/setting/partner-setup"
            className={({ isActive }) =>
              `flex flex-col items-center justify-center px-4 py-2 border rounded-md transition-all duration-300 ${
                isActive
                  ? "border-blue-500 text-blue-500"
                  : "border-gray-300 text-gray-800 hover:text-blue-500 hover:border-blue-500"
              }`
            }
          >
            {/* <span className="material-icons text-2xl">receipt</span> */}
            <span className="text-sm font-medium">Partner Integration</span>
          </NavLink>
          <NavLink
            to="/setting/gst-setup"
            className={({ isActive }) =>
              `flex flex-col items-center justify-center px-4 py-2 border rounded-md transition-all duration-300 ${
                isActive
                  ? "border-blue-500 text-blue-500"
                  : "border-gray-300 text-gray-800 hover:text-blue-500 hover:border-blue-500"
              }`
            }
          >
            {/* <span className="material-icons text-2xl">receipt</span> */}
            <span className="text-sm font-medium">GST/VAT</span>
          </NavLink>

          {/* Waiter Management Tab */}
          <NavLink
            to="/setting/waiter-management"
            className={({ isActive }) =>
              `flex flex-col items-center justify-center px-4 py-2 border rounded-md transition-all duration-300 ${
                isActive
                  ? "border-blue-500 text-blue-500"
                  : "border-gray-300 text-gray-800 hover:text-blue-500 hover:border-blue-500"
              }`
            }
          >
            {/* <span className="material-icons text-2xl">people</span> */}
            <span className="text-sm font-medium">Waiter</span>
          </NavLink>

          {/* Settings Tab */}
          {/* <NavLink
            to="/setting/general-settings"
            className={({ isActive }) =>
              `flex flex-col items-center justify-center px-4 py-2 border rounded-md transition-all duration-300 ${
                isActive
                  ? "border-blue-500 text-blue-500"
                  : "border-gray-300 text-gray-800 hover:text-blue-500 hover:border-blue-500"
              }`
            }
          >
            <span className="material-icons text-2xl">settings</span>
            <span className="text-sm font-medium">Settings</span>
          </NavLink> */}
        </div>
      </div>
    </header>
  )
}

export default SettingHeader