import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useOrder from "../hooks/useOrder";
import {
  customdatewise,
  daywise,
  monthwise,
  yearwise,
} from "../Component/Report/Services/ReportServices";
import { NavLink } from "react-router-dom";
import logo from "../img/logo.png";

const RestaurantStatsMobile = () => {
  const [day, setDay] = useState([]);
  const [month, setMonth] = useState([]);
  const [year, setYear] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [listOfBills, setListOfBills] = useState([]);
  const [localAmount, setLocalAmount] = useState({
    cash: 0,
    online: 0,
    card: 0,
    pending: 0,
  });
  const [activeTab, setActiveTab] = useState("today");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { getAllCompletedOrders } = useOrder();

  // Fetch report data logic
  const fetchReportData = async () => {
    const dayReport = await daywise();
    const orders = dayReport.data.bills;
    const channelWiseDistribution = dayReport.data.data.reduce((acc, data) => {
      const type = data.channel !== "qrapid" ? data.channel : data.name;
      acc[type] = data.totalAmount || 0;
      return acc;
    }, {});

    setLocalAmount({ ...channelWiseDistribution });
    setListOfBills(orders);

    const allAmount = Object.entries(channelWiseDistribution).map((entry) => ({
      name: entry[0],
      totalAmount: entry[1],
    }));

    setDay(allAmount);

    // Fetch month and year report data
    const monthReport = await monthwise();
    if (monthReport.data?.event) setMonth(monthReport.data.data);

    const yearReport = await yearwise();
    if (yearReport.data?.event) setYear(yearReport.data.data);
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  // Handle custom date filter
  const handleFilter = async () => {
    const result = await customdatewise({ startDate, endDate });
    if (result.data?.event) {
      setCustomData(result.data.data);
      setActiveTab("custom");
    }
  };

  // Tab change handler
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const payments = [
    {
      type: "Card Payment",
      data: listOfBills.filter((bill) => bill.name === "card"),
    },
    {
      type: "Online Payment",
      data: listOfBills.filter((bill) => bill.name === "online"),
    },
    {
      type: "Cash Payment",
      data: listOfBills.filter((bill) => bill.name === "cash"),
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      {/* Centered Content Wrapper */}
      <div className="max-w-screen-xl mx-auto">
        <>
          <NavLink to="/" className="flex flex-col items-center py-6">
            <img src={logo} alt="Logo" className="h-10 mb-2" />
            <h1 className="text-2xl font-bold text-green-600">QRAPID</h1>
          </NavLink>
        </>
        {/* Tab Navigation */}
        <div className="flex space-x-2 mb-6">
          {["today", "weekly", "yearly", "custom"].map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabChange(tab)}
              className={`px-4 py-2 text-sm font-medium rounded-md ${
                activeTab === tab
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Date Filter */}
        {activeTab === "custom" && (
          <div className="items-center">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMM d, yyyy"
              className="px-3 py-2 rounded-md border border-gray-300"
            />
            <br />
            <span className="text-gray-600">to</span>
            <br />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="MMM d, yyyy"
              className="px-3 py-2 rounded-md border border-gray-300"
            />
            <br />
            <button
              onClick={handleFilter}
              className="px-4 py-2 mt-2 rounded-md bg-blue-500 text-white"
            >
              Apply Filter
            </button>
          </div>
        )}

        {/* Stat Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {activeTab === "today" &&
            day.map((ele, ind) => (
              <StatCard
                key={ind}
                value={ele.totalAmount}
                label={`Today's ${ele.name}`}
                color={getStatCardColor(ele.name)}
              />
            ))}
          {activeTab === "weekly" &&
            month.map((ele, ind) => (
              <StatCard
                key={ind}
                value={ele.totalAmount}
                label={`Week's ${ele.name}`}
                color={getStatCardColor(ele.name)}
              />
            ))}
          {activeTab === "yearly" &&
            year.map((ele, ind) => (
              <StatCard
                key={ind}
                value={ele.totalAmount}
                label={`Year's ${ele.name}`}
                color={getStatCardColor(ele.name)}
              />
            ))}
          {activeTab === "custom" &&
            customData.map((ele, ind) => (
              <StatCard
                key={ind}
                value={ele.totalAmount}
                label={`Custom's ${ele.name}`}
                color={getStatCardColor(ele.name)}
              />
            ))}
        </div>

        {/* Payment Details */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {payments.map((payment, index) => (
            <div
              key={index}
              className="bg-white rounded-md shadow p-4 border border-gray-200"
            >
              <h3 className="text-lg font-semibold mb-4">{payment.type}</h3>
              <table className="w-full text-sm">
                <thead>
                  <tr>
                    <th className="text-left border-b pb-2">Bill No</th>
                    <th className="text-right border-b pb-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {payment.data.map((item, idx) => (
                    <tr key={idx}>
                      <td className="py-2 border-b">{item.billNo}</td>
                      <td className="py-2 border-b text-right">
                        {parseInt(item.amount).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const StatCard = ({ value, label, color }) => (
  <div className={`p-4 rounded-md text-white ${color}`}>
    <p className="text-2xl font-bold">{value}</p>
    <p className="text-sm">{label}</p>
  </div>
);

const getStatCardColor = (name) => {
  switch (name) {
    case "cash":
      return "bg-red-500";
    case "online":
      return "bg-orange-500";
    case "card":
      return "bg-green-500";
    case "pending":
      return "bg-purple-500";
    case "tips":
      return "bg-yellow-500";
    default:
      return "bg-gray-500";
  }
};

export default RestaurantStatsMobile;
