export const  Reprint = async (
  billNo,
  discount,
  totalAmount,
  items,

  roomData,
  orderDate = ''
) => {
  // Open a new window for printing
  // console.log(greenItems);
  const hotelData = {
    name: localStorage.getItem("name"),
    address: localStorage.getItem("address"),
  };

  const date = new Date(orderDate);
  const day = date.getDate(); // Day of the month (1-31)
  const month = date.getMonth() + 1; // Month (0-11, so we add 1 to get 1-12)
  const year = date.getFullYear();
  const time = '';
  // console.log(greenItems);

  // const data=
  // const d = date.split("(")[0]
  const totalBillAmount = parseInt(totalAmount);
  // const discounts = totalBillAmount-subTotal;
  // console.log(greenItems);

  let isGSTActive = localStorage.getItem("isGST");
  let including = true;
  let gstnNo = "";
  if (isGSTActive) {
    if (
      localStorage.getItem("gstPriceType") &&
      localStorage.getItem("gstPriceType") == "Include"
    ) {
      including = true;
      gstnNo = localStorage.getItem("GSTN");
    }
    if (
      localStorage.getItem("gstPriceType") &&
      localStorage.getItem("gstPriceType") == "Exclude"
    ) {
      including = false;
      gstnNo = localStorage.getItem("GSTN");
    }
  }

  // Construct the HTML string for the bill
  const billHtml = `
      <div style="padding: 0; margin:0; background-color: white; font-family: Arial, sans-serif; width: 100%; max-width: 300px;">
        <h2 style="text-align: center; font-size: 1rem; font-weight: bold; margin-bottom: 1rem;">${
          hotelData.name
        }</h2>
        <p style="text-align: center; font-size: 0.7rem; line-height: 1rem;">${
          hotelData.address
        }</p>
          ${
            isGSTActive == "true"
              ? `<p style="text-align: center; font-size: 0.7rem; line-height: 1rem;">
         GSTN: ${gstnNo}
        </p>`
              : ``
          }
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="font-size: 0.7rem; margin: 0.3rem 0;"><strong>Date:</strong> ${`${day} / ${month} / ${year} : ${time}`} </p>
        <p style="font-size: 0.9rem; margin: 0.5rem 0;"><strong>Table:</strong> TABLE ${
          roomData.table
        }<strong>      Bill No: </strong> ${billNo}</p>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <div>
          <table style="width: 100%; font-size: 0.7rem; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="text-align: left;">Item</th>
                <th style="text-align: right;">Qty.</th>
                <th style="text-align: right;">Price</th>
                <th style="text-align: right;">Amount</th>
              </tr>
            </thead>
            <tbody>
              ${items
                ?.map(
                  (item) => item.quantity > 0 && `
                <tr>
                  <td style="padding: 0.25rem 0;"><strong>${
                    item.name
                  }</strong></td>
                  <td style="text-align: right;">${item.quantity}</td>
                  <td style="text-align: right;">${
                    item.price ? item.price : item.amount
                  }</td>
                  <td style="text-align: right;">${
                    parseFloat(item.price || item.amount) *
                    parseFloat(item.quantity)
                  }</td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
        <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Sub Total:</strong> ₹${
          parseFloat(totalBillAmount) + parseFloat(discount)
        }</p>
        ${
          discount > 0
            ? ` <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Discount:<i>${parseInt((parseInt(discount)*100)/(parseInt(totalBillAmount) + parseInt(discount))).toFixed(2)} % </i>/</strong> ₹${parseInt(
                discount
              )}</p>`
            : ""
        } 
        ${
          isGSTActive == "true" && !including
            ? ` <p style="text-align: right; font-size: 0.7rem; margin: 0.5rem 0;"><strong>CGST 2.5%:</strong> ₹${
                (parseFloat(totalBillAmount) * 2.5) / 100
              }</p>
        <p style="text-align: right; font-size: 0.7rem; margin: 0.4rem 0;"><strong>SGST 2.5%:</strong> ₹${
          (parseFloat(totalBillAmount) * 2.5) / 100
        }</p>
`
            : ``
        }
        <p style="text-align: right; font-size: 0.9rem; margin: 0.5rem 0;"><strong>Grand Total:</strong> ₹${
          parseFloat(totalAmount) +
          (isGSTActive == "true" && !including
            ? (parseFloat(totalBillAmount) * 5) / 100
            : 0)
        }</p>
          ${
            isGSTActive == "true" && including
              ? ` <p style="text-align: right; font-size: 0.7rem; margin: 0.5rem 0;"><strong>CGST 2.5%:</strong> ₹${
                  (parseFloat(totalBillAmount) * 2.5) / 100
                }</p>
           <p style="text-align: right; font-size: 0.7rem; margin: 0.4rem 0;"><strong>SGST 2.5%:</strong> ₹${
             (parseFloat(totalBillAmount) * 2.5) / 100
           }</p>
   `
              : ``
          }
        <hr style="border: 0; border-top: 1px solid #000; margin: 0.5rem 0;" />
         ${
           isGSTActive == "true" && including
             ? `    <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">GST Already Included in Item Price </p>`
             : ``
         }
        <p style="text-align: center; font-size: 0.7rem; margin: 0.5rem 0;">Thank you for visiting! @Qrapid</p>
      </div>
    `;
  // Write the HTML to the new window
  const WinPrint = window.open("", "", "width=800,height=600");
  WinPrint.document.write(billHtml);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  
  return WinPrint.close();
};
