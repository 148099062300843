// src/components/StatCard.js
import React from 'react';

const StatCard = ({ color, value, label }) => {
  return (
    <div className={`flex-1 p-6 text-white rounded-lg ${color}`}>
      <h2 className="text-3xl font-bold">{value?.toFixed(2)}</h2>
      <p className="mt-2 text-lg">{label}</p>
    </div>
  );
};

export default StatCard;
