import React, { useState, useEffect } from 'react';
import { useCloud } from '../../../hooks/useCloud';

const PartnerIntegration = () => {
  const { partnerAPIIngegration } = useCloud();
  const [restaurantId, setRestaurantId] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [integratedPartners, setIntegratedPartners] = useState([]);

  // const[ zomatoOutlate , setZomatoOutlate ]  = useState();

  // Load data from local storage on component mount
  useEffect(() => {
    const storedPartners = JSON.parse(localStorage.getItem('integratedPartners')) || [];
    setIntegratedPartners(Array.isArray(storedPartners) ? storedPartners : []);
  }, []);

  // Save data to local storage whenever `integratedPartners` updates
  useEffect(() => {
    localStorage.setItem('integratedPartners', JSON.stringify(integratedPartners));
  }, [integratedPartners]);

  const handleSubmit = async () => {
    if (restaurantId && selectedPartner) {
      const newPartner = { partner: selectedPartner, id: restaurantId };

      // Simulate an API call
      const obj = {
        channelName: selectedPartner,
        channelId: restaurantId,
        isActive: true,
      };

      try {
        const response = await partnerAPIIngegration(obj);
        localStorage.setItem(selectedPartner,restaurantId )

        if (response.success) {
          setIntegratedPartners((prev) => (Array.isArray(prev) ? [...prev, newPartner] : [newPartner]));
          setRestaurantId(''); // Clear input
          setSelectedPartner(''); // Clear selected partner
        } else {
          console.error('Failed to integrate partner');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleEdit = (index) => {
    const partnerToEdit = integratedPartners[index];
    setRestaurantId(partnerToEdit.id);
    setSelectedPartner(partnerToEdit.partner);
    setIntegratedPartners((prev) => (Array.isArray(prev) ? prev.filter((_, i) => i !== index) : []));
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-md mx-auto">
      <h1 className="text-xl font-semibold text-center mb-4">Partner Integration</h1>

      <div className="space-y-4">
        {/* Partner Selection */}
        <div>
          <label className="block font-medium mb-2">Select Partner:</label>
          <div className="flex gap-4">
            <button
              className={`px-4 py-2 border rounded ${
                selectedPartner === 'Swiggy' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
              }`}
              onClick={() => setSelectedPartner('Swiggy')}
            >
              Swiggy
            </button>
            <button
              className={`px-4 py-2 border rounded ${
                selectedPartner === 'Zomato' ? 'bg-red-500 text-white' : 'bg-white text-gray-700'
              }`}
              onClick={() => setSelectedPartner('Zomato')}
            >
              Zomato
            </button>
          </div>
        </div>

        {/* Restaurant ID Input */}
        <div>
          <label className="block font-medium mb-2">Restaurant ID:</label>
          <input
            type="text"
            value={restaurantId}
            onChange={(e) => setRestaurantId(e.target.value)}
            className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter restaurant ID"
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={!restaurantId || !selectedPartner}
        >
          Submit
        </button>
      </div>

      {/* Integrated Partners List */}
      {integratedPartners.length > 0 && (
        <div className="mt-6">
          <h2 className="text-lg font-semibold mb-3">Integrated Partners:</h2>
          <ul className="space-y-3">
            {integratedPartners.map((partner, index) => (
              <li
                key={index}
                className="flex justify-between items-center p-3 bg-white rounded shadow"
              >
                <span>
                  <strong>{partner.partner}</strong> - {partner.id}
                </span>
                <button
                  onClick={() => handleEdit(index)}
                  className="text-blue-500 hover:underline"
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PartnerIntegration;
