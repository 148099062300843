export const swiggyPrintKOT = ({ items, orderId }) => {
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    const formattedTime = date.toLocaleTimeString();
  
    const kotHTML = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 10px;
            }
            h2 {
              text-align: center;
              margin: 0 0 10px 0;
              font-size: 18px;
            }
            .details {
              margin-bottom: 10px;
              font-size: 14px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              font-size: 14px;
            }
            th, td {
              padding: 8px;
            }
            th {
              text-align: left;
              background-color: #f2f2f2;
            }
            td {
              vertical-align: top;
            }
            .customisation {
              padding-left: 10px;
              font-style: italic;
              font-size: 12px;
              color: #555;
            }
          </style>
        </head>
        <body>
          <h2>Kitchen Order Ticket (KOT)</h2>
          <div class="details">
            <p><strong>Date:</strong> ${formattedDate}</p>
            <p><strong>Time:</strong> ${formattedTime}</p>
            <p><strong>Order ID:</strong> ${String(orderId).slice(-4)}</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th>Customisations</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              ${items
                .map((item) => `
                  <tr>
                    <td>${item.name}</td>
                    <td>
                      ${item.addons
                        ? item.addons
                            .map(
                              (addon) => `
                              <div class="addon">${addon.name} (₹${addon.price})</div>
                            `
                            )
                            .join('')
                        : '-'}
                    </td>
                    <td>${item.quantity}</td>
                  </tr>
                `)
                .join('')}
            </tbody>
          </table>
        </body>
      </html>
    `;
  
    // Open a new window and print the KOT
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(kotHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  