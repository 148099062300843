import React, { useEffect, useState } from "react";
import logo from "../../img/logo.png";
import tableIcon from "../../img/tableIcon.svg";
import dashboardIcon from "../../img/dashboardIcon.svg";
import menuIcon from "../../img/menuIcon.svg";
import orderIcon from "../../img/orderIcon.svg";
import reportsIcon from "../../img/reportsIcon.svg";
import settingIcon from "../../img/settingIcon.svg";
// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import useOrder from "../../hooks/useOrder";
import { useCloud } from "../../hooks/useCloud";
import FullscreenComponent from "../FullscreenComponent";

const Header = () => {
  const { getAllCompletedOrders, flushAllCompleteOrders } = useOrder();
  const { syncCloud } = useCloud();
  const [token, setToken] = useState();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [cloudSync, setCloudSync] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(localStorage.getItem("authToken"));
          }, 500);
        });
        setToken(token);
      } catch (error) {
        console.error("Failed to fetch token", error);
      }
    };

    fetchToken();
  }, []);

  const handleCloudSync = async () => {
    setCloudSync(true);

    try {
      const orders = await getAllCompletedOrders();
      const response = await syncCloud(orders);
      if (response) {
        await flushAllCompleteOrders();
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations.forEach((registration) => {
            registration.unregister(); // Unregister the Service Worker
          });
        });

        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName); // Clear Cache
          });
        });

        // localStorage.setItem("lastBillNo",parseInt(localStorage.getItem("lastBillNo"))+parseInt(orders.length))
      }
      setCloudSync(false);
    } catch (err) {
      console.log(err);
      setCloudSync(false);
    }
  };

  return (
    <>
      <header className="m-auto flex items-center justify-end border-b border-[#D9D9D9] fixed top-0 bg-white left-0 right-0 w-full p-8 h-[80px] z-20">
        <div className="flex justify-end items-center gap-[20px]">
          {/* Fullscreen toggle button */}
          <NavLink
            to="/hotel-managment"
            className="flex items-center flex-col gap-[6px]"
          >Managment</NavLink>
          <FullscreenComponent /> {/* Add this to allow fullscreen toggle */}
          <span>
            <i className="fas fa-bell"></i>
          </span>
          <span className="text-white bg-[#16a34a] py-2.5 px-4 rounded-xl">
            AS
          </span>
        </div>
      </header>
      <aside
        className={`bg-white ${
          isCollapsed ? "w-16" : "w-40"
        } min-h-screen fixed top-0 bottom-0 left-0 right-0 transition-all duration-300 border-r border-[#D9D9D9] bg-white z-50 pb-10 overflow-scroll hide-scrollbar`}
      >
        <div>
          <>
            <NavLink to="/" className="flex flex-col items-center py-6">
              <img src={logo} alt="Logo" className="h-10 mb-2" />
              <h1 className="text-2xl font-bold text-green-600">QRAPID</h1>
            </NavLink>
          </>
          {/* <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="mb-4 focus:outline-none"
          >
            <i
              className={`fas fa-${isCollapsed ? "bars" : "times"} text-xl`}
            ></i>
          </button>
          {!isCollapsed && (
            <>
              <img src={logo} alt="Logo" className="h-10 mb-4" />
              <h1 className="text-2xl font-bold text-green-600">QRAPID</h1>
            </>
          )} */}
        </div>
        <nav className="qrapid-sidebar flex flex-col gap-[5px]">
          <NavLink
            to="/table-overview"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-table"></i> */}
            <img src={tableIcon} alt="table icon" />
            {!isCollapsed && <span>Table</span>}
          </NavLink>
          <NavLink
            to="/reports"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-chart-line"></i> */}
            <img src={dashboardIcon} alt="dashboard icon" />
            {!isCollapsed && <span>Dashboard</span>}
          </NavLink>
          <NavLink
            to="/menu-managment"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-utensils"></i> */}
            <img src={menuIcon} alt="menu icon" />
            {!isCollapsed && <span>Menu</span>}
          </NavLink>
          <NavLink
            to="/order-main"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-receipt"></i> */}
            <img src={orderIcon} alt="order icon" />
            {!isCollapsed && <span>Orders</span>}
          </NavLink>
          <NavLink
            to="/order-list-lifetime"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-file-alt"></i> */}
            <img src={reportsIcon} alt="reports icon" />
            {!isCollapsed && <span>Reports</span>}
          </NavLink>
          <NavLink
            to="/setting"
            className="flex items-center flex-col gap-[6px]"
          >
            {/* <i className="fas fa-cog"></i> */}
            <img src={settingIcon} alt="setting icon" />
            {!isCollapsed && <span>Settings</span>}
          </NavLink>
        </nav>
        <div className="flex items-center flex-col justify-center mt-8">
          {token && (
            <button
              onClick={() => {
                localStorage.removeItem("authToken");
                handleCloudSync();

                window.location.reload();
              }}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
            >
              Logout
            </button>
          )}
        </div>
      </aside>
    </>
  );
};

export default Header;
