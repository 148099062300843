import React, { useEffect, useState } from "react";

import { useCloud } from "../../../hooks/useCloud";
import { observer } from "mobx-react-lite";
import { set } from "mobx";


const WaiterManagment = () => {
  const { getWaiterList, changeStatusWaiter, addNewWaiter } = useCloud();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [waiterList, setWaiterList] = useState([]);
  const [waiterName, setWaiterName] = useState("");

  useEffect(() => {
    const fetchWaiterList = async () => {
      const list = await getWaiterList();
      if (list.success) {
        setWaiterList(list.waiters);
      }
      console.log(list, "this is list of waiter");
    };
    fetchWaiterList();
  }, []);

  const filteredItems = waiterList.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const changeStatus = async (item) => {
    const payload = { waiterId: item.id, name: item.name };
    console.log(payload, "this is payload");

    const response = await changeStatusWaiter(payload);
    if (response.event) {
      alert("Waiter status changed successfully");
      setWaiterList(
        waiterList.map((waiter) =>
          waiter.id === item.id
            ? { ...waiter, isAvailable: !waiter.isAvailable }
            : waiter
        )
      );
    } else {
      alert("Waiter status not changed");
    }
  };

  const addWaiter = async (e) => {
    e.preventDefault();
    const payload = { name: waiterName };
    console.log(payload, "this is payload");

    const response = await addNewWaiter(payload);
    if (response.event) {
      alert("Waiter added successfully");
      setWaiterList([
        ...waiterList,
        { id: response.waiter.id, name: waiterName, isAvailable: true },
      ]);
      setIsModalOpen(false);
    } else {
      alert("Waiter not added");
    }
  };

  return (
    <div className="p-4">
        <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search item or code"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:ring-blue-300"
        />
        <div className="flex gap-2">
          
          <button
            onClick={toggleModal}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            + Add Waiter
          </button>
          <button className="bg-gray-200 text-gray-600 px-3 py-2 rounded-lg hover:bg-gray-300">
            ...
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-200 px-4 py-2 text-left">
                <input type="checkbox" />
              </th>
              <th className="border border-gray-200 px-4 py-2 text-left">Code</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Name</th>
              {/* <th className="border border-gray-200 px-4 py-2 text-left">Price</th>
              <th className="border border-gray-200 px-4 py-2 text-left">AC Price</th> */}
              {/* <th className="border border-gray-200 px-4 py-2 text-left">Type</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Ratings</th> */}
              <th className="border border-gray-200 px-4 py-2 text-left">Status</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Change Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-200 px-4 py-2">
                    {/* <input type="checkbox" /> */}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">{index}</td>
                  <td className="border border-gray-200 px-4 py-2 flex items-center gap-2">
                    {/* <img
                      src={item.image}
                      alt={item.name}
                      className="w-10 h-10 rounded-md"
                    /> */}
                    {item.name}
                  </td>
                  {/* <td className="border border-gray-200 px-4 py-2">{item.price}</td>
                  <td className="border border-gray-200 px-4 py-2">{item.acPrice}</td> */}
                  {/* <td className="border border-gray-200 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-200 px-4 py-2">{item.ratings}</td> */}
                  <td className="border border-gray-200 px-4 py-2 text-green-600">
                    {item.isAvailable? "Available" : "Not Available" 
}
                  </td>
                  <td className="border border-gray-200 px-4 py-2 flex gap-2">
                    <button onClick={()=>{
                      changeStatus(item)}
                    } className="text-black-500 hover:text-blue-700 bg-blue-200 px-4 py-2 rounded-lg hover:bg-blue-300">
                      Change Status
                    </button>
                    {/* <button className="text-red-500 hover:text-red-700">🗑️</button> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="9"
                  className="border border-gray-200 px-4 py-2 text-center text-gray-500"
                >
                  No items found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-1/3">
            <h2 className="text-xl font-bold mb-4">Add New Waiter</h2>
            <p className="mb-4">Enter details for the new Waiter.</p>
            <input
              type="text"
              placeholder="Enter Waiter Name"
              className="border p-2 w-full mb-2"
              value={waiterName}
              onChange={(e) => setWaiterName(e.target.value)}

            />
            {/* <h1>Inprogress</h1> */}
           <div className="flex justify-between">
           <button
              onClick={toggleModal}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
            >
              Close
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              onClick={addWaiter}

            >
                Add Waiter

            </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer( WaiterManagment);

