import React, { useState } from "react";
import CategoryList from "./CategoryList";
import ItemGrid from "./ItemGrid";
import Cart from "./Cart";
import OrderSummary from "./OrderSummary";
import { createOrderByRestarunt } from "../../Service/KotStatusChange.service";
import { printKOT } from "../../../Print/PrintKOT";
import { cancleAndUpdate } from "../../../Print/CancleAndUpdateKOT";
import { addExtraAmount } from "./MenuAppServices/Manuapp.services";
import toast, { Toaster } from "react-hot-toast";
import useOrder from "../../../../hooks/useOrder";

const CategorySearch = ({ categorySearchQuery, setCategorySearchQuery }) => (
  <div className="mb-4">
    <input
      type="text"
      placeholder="Search categories..."
      value={categorySearchQuery}
      onChange={(e) => setCategorySearchQuery(e.target.value)}
      className="w-full p-2 border rounded"
    />
  </div>
);

const ItemSearch = ({ itemSearchQuery, setItemSearchQuery, setExtra }) => (
  <div className="mb-4 flex">
    <input
      type="text"
      placeholder="Search items..."
      value={itemSearchQuery}
      onChange={(e) => setItemSearchQuery(e.target.value)}
      className="w-full p-2 border rounded"
    />
    <button
      className="min-w-20 bg-blue-400 ml-5 rounded"
      onClick={() => {
        setExtra(true);
      }}
    >
      Add Extra
    </button>
  </div>
);

const ExtraItemForm = ({
  extra,
  name,
  setName,
  quantity,
  setQuantity,
  price,
  setPrice,
  handleSubmit,
  handleSubmitSave,
}) => {
  const save = (e) => {
    e.preventDefault();

    const extraItem = { name, qty: quantity, price };
    // console.log(extraItem,"...");
    
    if(quantity =="" && price==""){
      alert("Add Correct Data ");
      return ;
    }else{

      setName("");
      setQuantity("");
      setPrice("");
      handleSubmitSave(extraItem);
    }
  };
  // const  saveAndPrint=()=>{

  // }

  if (!extra) return "";
  return (
    <form className="flex items-center space-x-4 mb-10">
      <input
        type="text"
        placeholder="Enter Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="border p-2 rounded w-48"
      />
      <input
        type="number"
        placeholder="Enter Quantity"
        value={quantity}
        onChange={(e) => {
          const value = Math.min(Math.max(e.target.value, 0), 50); // Restrict between 0 and 50
          setQuantity(value);
        }}
        className="border p-2 rounded w-30"
      />
      <label className="flex items-center space-x-2">
        <input
          type="number"
          value={price}
          onChange={(e) => {
            const value = Math.min(Math.max(e.target.value, 0), 10000); // Restrict between 0 and 10000
            setPrice(value);
          }}
          className="border p-2 rounded w-24"
          placeholder="Enter price"
        />
      </label>
      <span>
        Rs: {quantity && price && parseFloat(price) * parseFloat(quantity)}
      </span>
      <div className="flex justify-end"></div>
      <button
        onClick={save}
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Add to Bill
      </button>
      {/* <button
        onClick={save}
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Add  & PrintKOT
      </button> */}
    </form>
  );
};

const CartSection = ({
  cartItems,
  handleQuantityChange,
  handleDeleteItem,
  handleCloseCart,
  bookedOrder,
  extraCartItems,
  billNo,
  HandleKOTfilterItem,
  setComment,
  comment
}) => (
  <Cart
    cartItems={cartItems}
    onQuantityChange={handleQuantityChange}
    onDeleteItem={handleDeleteItem}
    onCloseCart={handleCloseCart}
    bookedOrder={bookedOrder}
    extraCartItems={extraCartItems}
    billNo={billNo}
    filterItem={HandleKOTfilterItem}
    setComment={setComment}
    comment={comment}
  />
);

const OrderSummarySection = ({
  cartItems,
  total,
  saveAndPrintOrder,
  billNo,
  saveOrder,
  cancleAndUpdateKOT,
  cancelOrder,
  error,
}) => (
  <>
    <OrderSummary
      cartItems={cartItems}
      total={total}
      itemCount={cartItems.length}
      bookOrder={saveAndPrintOrder}
      billNo={billNo}
      saveOrder={saveOrder}
      cancleAndUpdateKOT={cancleAndUpdateKOT}
      cancelOrder={cancelOrder}
    />
    {error && <p className="text-red-500 ">{error}</p>}
  </>
);

const MenuApp = ({
  data,
  menu,
  bookedOrder: initialBookedOrder,
  closeEdit,
  billNo,
  oldKOTNotPrinted,
}) => {
  const { processOrder, cancelOrder } = useOrder();
  const [selectedCategory, setSelectedCategory] = useState(
    menu && menu.length > 0 ? menu[0] : { name: "", items: [] }
  );
  const [cartItems, setCartItems] = useState([]);
  const [bookedOrder, setBookedOrder] = useState(initialBookedOrder);
  const [cancleKoTItem, setCancleKOTitem] = useState([]);
  const [error, setError] = useState("");
  const [itemSearchQuery, setItemSearchQuery] = useState(""); // Search query for items
  const [categorySearchQuery, setCategorySearchQuery] = useState(""); // Search query for categories

  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [extraCartItems, setExtraCartItems] = useState([]);
  const [extra, setExtra] = useState(false);
  const [comment, setComment] = useState("");

  const handleItemClick = (item) => {
    const existingItem = cartItems.find(
      (cartItem) =>
        cartItem.name === item.name &&
        cartItem.selectedVariant?.id === item.selectedVariant?.id // Check if the variant is the same
    );

    if (existingItem) {
      // If the item with the same variant already exists, update the quantity
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.name === item.name &&
          cartItem.selectedVariant?.id === item.selectedVariant?.id
            ? { ...cartItem, qty: cartItem.qty + 1 }
            : cartItem
        )
      );
    } else {
      // If it's a new item or variant, add it to the cart with qty = 1
      setCartItems([...cartItems, { ...item, qty: 1 }]);
    }
  };

  const handleQuantityChange = (name, variantId, qty) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.name === name && cartItem.selectedVariant?.id === variantId
          ? { ...cartItem, qty }
          : cartItem
      )
    );
  };

  const handleDeleteItem = (name, variantId) => {
    setCartItems(
      cartItems.filter(
        (cartItem) =>
          cartItem.name !== name || cartItem.selectedVariant?.id !== variantId
      )
    );
  };

  const handleCloseCart = () => {
    setCartItems([]);
    closeEdit();
  };

  const total = cartItems?.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );

  const convertCartToOrderFormat = (cartItems) => {
    return cartItems?.map((item) => ({
      itemId: item.id,
      quantity: item.qty,
      varientid: item?.selectedVariant?.id,
    }));
  };

  const saveAndPrintOrder = async () => {
    const response = await saveOrder();
    if (response) {
      printKOT("check In", cartItems, data, extraCartItems,comment);
    }
  };

  const cancleAndUpdateKOT = async () => {
    console.log({
      cartItems,
      cancleKoTItem,
      extraCartItems,
    });
    const response = await saveOrder();
    if (response) {
      cancleAndUpdate(
        "Cancle KOT",
        cartItems,
        data,
        cancleKoTItem,
        extraCartItems,
        comment
      );
    }
    // const formattedItems = convertCartToOrderFormat(cartItems);
    // if (cartItems?.length > 0) {
    //   try {
    //     const response = await createOrderByRestarunt({
    //       name: "NA",
    //       mobile: "NA",
    //       tableVPA: data.tableVPA,
    //       items: formattedItems,
    //     });
    //     console.log("Order Response: ", response);
  };

  const HandleKOTfilterItem = (itemid, item) => {
    const updatedBookedOrder = bookedOrder.filter(
      (i) => i.uniqueId !== itemid || i.name !== item.name
    );

    setBookedOrder(updatedBookedOrder);
    setCancleKOTitem((prevItems) => [...prevItems, item]);
  };

  // Filter all items from all categories when there's a search query
  const filteredItems = itemSearchQuery
    ? menu.flatMap((category) =>
        category.items.filter((item) =>
          item.name.toLowerCase().includes(itemSearchQuery.toLowerCase())
        )
      )
    : selectedCategory.items;

  // Filter categories based on the category search query
  const filteredCategories = menu?.filter((category) =>
    category.name.toLowerCase().includes(categorySearchQuery.toLowerCase())
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleSubmitSave = async (extraItem) => {
    setExtra(false);
    setExtraCartItems((prv) => [...prv, extraItem]);
  };

  const saveOrder = async () => {
    let rawOrder = [...cartItems, ...extraCartItems];
    rawOrder = rawOrder.map((item) => ({
      itemId: item.id,
      name: item.name,
      price: item.price || "0",
      variant: item.variant || [],
      quantity : item.qty,
      uniqueId: item.id + (item?.selectedVariant ? `_${item.selectedVariant.id}` : ""),
      selectedVariant: item.selectedVariant,
    }));
    const formatedOrder = convertCartToOrderFormat(cartItems);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await processOrder({
          cancleOrders: cancleKoTItem,
          extraOrders: extraCartItems,
          rawOrder,
          formatedOrder,
          tableVPA: data.tableVPA,
        });
        if (response) {
          resolve(true);
          handleCloseCart();
        }
      } catch (error) {
        reject(error);
        toast.error("Something went wrong please try again");
      }
    });
  };

  const cancleOrderHandler = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await cancelOrder(data.tableVPA);
        if (response) {
          resolve(true);
          handleCloseCart();
        } else {
          reject(false);
        }
      } catch (error) {
        reject(false);
        toast.error("Something went wrong please try again");
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex z-50">
      <div className="w-full h-full bg-white flex">
        {/* Category List */}
        <div className="w-1/4 bg-gray-200 p-4">
          <CategorySearch
            categorySearchQuery={categorySearchQuery}
            setCategorySearchQuery={setCategorySearchQuery}
          />
          <CategoryList
            categories={filteredCategories}
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />
        </div>

        {/* Item Grid */}
        <div className="w-2/3 bg-gray-200 p-4 overflow-y-auto">
          <ItemSearch
            itemSearchQuery={itemSearchQuery}
            setItemSearchQuery={setItemSearchQuery}
            setExtra={setExtra}
          />
          <ExtraItemForm
            extra={extra}
            name={name}
            setName={setName}
            quantity={quantity}
            setQuantity={setQuantity}
            price={price}
            setPrice={setPrice}
            handleSubmit={handleSubmit}
            handleSubmitSave={handleSubmitSave}
          />
          {filteredItems && (
            <ItemGrid items={filteredItems} onItemSelect={handleItemClick} />
          )}
        </div>

        {/* Cart and Order Summary */}
        <div className="w-1/3 bg-white p-6 border-l border-gray-300 scrollable-hover">
          <CartSection
            cartItems={cartItems}
            handleQuantityChange={handleQuantityChange}
            handleDeleteItem={handleDeleteItem}
            handleCloseCart={handleCloseCart}
            bookedOrder={bookedOrder}
            extraCartItems={extraCartItems}
            billNo={billNo}
            HandleKOTfilterItem={HandleKOTfilterItem}
            setComment={setComment}
            comment={comment}
          />
          <OrderSummarySection
            cartItems={cartItems}
            total={total}
            saveAndPrintOrder={saveAndPrintOrder}
            billNo={billNo}
            saveOrder={saveOrder}
            cancleAndUpdateKOT={cancleAndUpdateKOT}
            cancelOrder={cancleOrderHandler}
            error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default MenuApp;
