import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/common";

const AuthGuard = ({ children }) => {
  const isAuth = isAuthenticated();
  if (!isAuth) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default AuthGuard;
