import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { reportallYear } from "./Services/ReportServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";
import useOrder from "../../hooks/useOrder";
import { useCloud } from "../../hooks/useCloud";
import { observer } from "mobx-react-lite";
import { Reprint } from "../Print/ReprintBill";
import store from "../../store/index";
import { formatDate } from "../../utils/common";

const dateToString = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};
// Utility function for calculating the total amount
const calculateTotalAmount = (orders) => {
  return orders
    .filter(
      (order) =>
        order.paymentmethod !== "order cancle" && order.paymentmethod !== "NA"
    )
    .reduce((acc, order) => acc + order.amount, 0);
};

// Reusable Button component for various filters
const FilterButton = ({ label, onClick, className }) => (
  <button onClick={onClick} className={`px-4 py-2 rounded ${className}`}>
    {label}
  </button>
);

const OrderListLifeTime = () => {
  const { getOrderHistory } = useOrder();
  const { getBillInfo, getOrderHistory: getOrderHistoryCloud } = useCloud();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);

  // Filter orders by specific method
  const filterByMethod = (method) => {
    const orders = filterOrdersByDate();
    const filtered = orders.filter((order) => order.paymentmethod === method);
    setFilteredData(filtered);
    setTotalAmount(calculateTotalAmount(filtered));
  };

  const filterByDate = (date) => {
    setStartDate(date);
    setEndDate(date);
    const filtered = orders.filter((order) => order.orderDate === date);
    setFilteredData(filtered);
    setTotalAmount(calculateTotalAmount(filtered));
  };

  // Filter orders for last 'n' days
  const filterLastNDays = (days) => {
    const today = new Date();
    const previousDate = new Date();
    previousDate.setDate(today.getDate() - days);
    setStartDate(previousDate);
    setEndDate(today);
    const filtered = filterOrdersByDate();
    setFilteredData(filtered);
    setTotalAmount(calculateTotalAmount(filtered));
    return filtered;
  };

  const filterOrdersByDate = () => {
    let start = new Date(startDate).setHours(0, 0, 0, 0);
    let end = new Date(endDate).setHours(23, 59, 59, 999);

    const filtered = orders.filter((order) => {
      console.log(order.orderDate);
      return (
        new Date(order.orderDate) >= start && new Date(order.orderDate) <= end
      );
    });
    setFilteredData(filtered);
    setTotalAmount(calculateTotalAmount(filtered));
    return filtered;
  };

  const filterByIntegration = (integration) => {
    const orders = filterOrdersByDate();
    const filtered = orders.filter(
      (order) => order.invoiceNo.includes(integration)
    );
    console.log(filtered);
    setFilteredData(filtered);
    setTotalAmount(calculateTotalAmount(filtered));
  };

  // Reset filters
  const resetFilters = () => {
    const yearback = new Date();
    yearback.setFullYear(yearback.getFullYear() - 1); // Subtract one year
setStartDate(yearback);
    setEndDate(new Date());
    filterOrdersByDate();
  };

  // Handle bill print
  const handleBillPrint = async (order) => {
    const orderData = await getBillInfo({ billNo: order.invoiceNo });
    order = { ...order, ...orderData };
    await Reprint(
      order.invoiceNo,
      order.order.discount,
      order.amount,
      order.items,
      { table: order.table.split(" ")[1] },
      order.orderDate
    );
  };

  // Fetch all orders
  const getAllOrders = async () => {
    const allOrders = await getOrderHistory();
    setFilteredData(allOrders);
    setTotalAmount(calculateTotalAmount(allOrders));
    return allOrders;
  };

  useEffect(() => {
    const fetchData = async () => {
      const cloudOrderHistory = await getOrderHistoryCloud();
      store.updateOrderHistory(cloudOrderHistory);
      const orders = await getAllOrders();
      setOrders(orders);
      setFilteredData(orders);
      setLoading(false);
    };
    fetchData();
  }, []);

  const csvDataWithTotal = [
    ...(filteredData.length > 0 ? filteredData : orders),
    {
      invoiceNo: "",
      customerName: "",
      waiter: "",
      table: "",
      state: "",
      orderDate: "",
      amount: `Total: RS ${totalAmount}`,
      paymentmethod: "",
      tips: "",
    },
  ];

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
        <div className="text-white text-lg">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-6">Order List</h2>

      {/* Date Filters */}
      <div className="flex items-end space-x-4 mb-4">
        <div>
          <label className="block font-semibold">From:</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="p-2 border rounded"
            dateFormat="dd MMM yyyy"
          />
        </div>
        <div>
          <label className="block font-semibold">To:</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="p-2 border rounded"
            dateFormat="dd MMM yyyy"
          />
        </div>
        <FilterButton
          label="Search"
          onClick={() => filterOrdersByDate()}
          className="bg-green-500 text-white"
        />
        <FilterButton
          label="Reset"
          onClick={resetFilters}
          className="bg-orange-500 text-white"
        />
      </div>

      {/* Payment Method Filter */}
      <div className="flex space-x-2 mb-4">
        {["cash", "online", "card", "partial"].map((method) => (
          <FilterButton
            key={method}
            label={method.charAt(0).toUpperCase() + method.slice(1)}
            onClick={() => filterByMethod(method)}
            className={`bg-${
              method === "cash"
                ? "blue"
                : method === "online"
                ? "yellow"
                : method === "card"
                ? "green"
                : "red"
            }-500 text-white`}
          />
        ))}
      </div>

      {/* Date Range Filter Buttons */}
      <div className="flex space-x-2 mb-4">
        {[
          {
            label: "Today",
            action: () => filterByDate(formatDate(new Date())),
          },
          {
            label: "Yesterday",
            action: () =>
              filterByDate(
                formatDate(
                  new Date(new Date().setDate(new Date().getDate() - 1))
                )
              ),
          },
          { label: "Last 7 Days", action: () => filterLastNDays(7) },
          { label: "Last 30 Days", action: () => filterLastNDays(30) },
        ].map(({ label, action }) => (
          <FilterButton
            key={label}
            label={label}
            onClick={action}
            className="bg-pink-500 text-white"
          />
        ))}
      </div>

      {/* Date Range Filter Buttons */}
      <div className="flex space-x-2 mb-4">
        {[
          {
            label: "zomato",
            action: () => filterByIntegration("Zomato"),
          },
          {
            label: "swiggy",
            action: () => filterByIntegration("swiggy"),
          },
        ].map(({ label, action }) => (
          <FilterButton
            key={label}
            label={label}
            onClick={action}
            className="bg-yellow-500 text-white"
          />
        ))}
      </div>

      {/* Total Amount Display */}
      <div className="mb-4 font-semibold text-lg">
        Total Amount: RS {totalAmount?.toFixed(2)} 
      </div>

      {/* CSV Download */}
      <div className="flex space-x-2 mb-4">
        <CSVLink
          data={csvDataWithTotal}
          headers={[
            { label: "SI", key: "id" },
            { label: "Invoice No", key: "invoiceNo" },
            { label: "Customer Name", key: "customerName" },
            { label: "Waiter", key: "waiter" },
            { label: "Table", key: "table" },
            { label: "State", key: "state" },
            { label: "Order Date", key: "orderDate" },
            { label: "Amount", key: "amount" },
            { label: "Method", key: "paymentmethod" },
            { label: "Tips", key: "tips" },
          ]}
          filename={"orders_with_total_amount.csv"}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Download File
        </CSVLink>
      </div>

      {/* Order Table */}
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2">Invoice No</th>
            <th className="py-2">Customer Name</th>
            <th className="py-2">Waiter</th>
            <th className="py-2">Table</th>
            <th className="py-2">State</th>
            <th className="py-2">Order Date</th>
            <th className="py-2">Amount</th>
            <th className="py-2">Method</th>
            <th className="py-2">Tips</th>
            <th className="py-2">Bill</th>
          </tr>
        </thead>
        <tbody>
          {filteredData
            .sort((a, b) => b.invoiceNo - a.invoiceNo)
            .map((order, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{order.invoiceNo}</td>
                <td className="border px-4 py-2">{order.customerName}</td>
                <td className="border px-4 py-2">{order.waiter}</td>
                <td className="border px-4 py-2">{order.table}</td>
                <td className="border px-4 py-2">{order.state}</td>
                <td className="border px-4 py-2">
                  {dateToString(order.orderDate)}
                </td>
                <td className="border px-4 py-2">
                  <div>{order.amount}</div>
                  {order.paymentmethod === "partial" &&
                    order.partialAmoutsplit?.map((ele, idx) => (
                      <p key={idx}>
                        {ele.name}: {ele.amount}
                      </p>
                    ))}
                </td>
                <td className="border px-4 py-2">{order.paymentmethod}</td>
                <td className="border px-4 py-2">{order.tips}</td>
                <td className="border px-4 py-2 cursor-pointer">
                  <FontAwesomeIcon
                    icon={faPrint}
                    className="mr-3"
                    onClick={() => handleBillPrint(order)}
                  />
                </td>
              </tr>
            ))}
          <tr>
            <td colSpan="6" className="text-right font-bold">
              Total Amount
            </td>
            <td colSpan="2">RS {totalAmount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default observer(OrderListLifeTime);
