import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import TableOverview from "./Component/TableOverview";
import Header from "./Component/Header/Header";
import Reports from "./Component/Report/Report";
import AcceptOrder from "./Component/AcceptOrder/AcceptOrder.main";
import OrderListLifeTime from "./Component/Report/OrdersListLifetime";
import OrderMain from "./Component/Orders/OrderMain";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Link,
 
} from "react-router-dom";
import AuthGuard from "./Component/AuthGard/AuthGuard";
import Login from "./Component/AuthGard/LogIn";
import ItemManagement from "./Component/Menu/MenuManagament";
import Setting from "./Component/Setting/Setting";
import SignUp from "./Component/AuthGard/SignUp";
import { ParcelMain } from "./Component/Parcel/Parcel.main";
import toast, { Toaster } from "react-hot-toast";

import { useCloud } from "./hooks/useCloud";
import { useStore } from "./hooks/useStore";
import useOrder from "./hooks/useOrder";
import store from "./store/index";
import { openDB } from "idb";
import SignUpNew from "./Component/AuthGard/SignUpNew";
import ManagerForm from "./Component/AuthGard/ManagerForm";
import Room from "./Component/AuthGard/Room";
import Managment from "./Component/Managment/Managment";
import { messaging } from "./firebase";
import { onMessage } from "firebase/messaging";
import { playRing } from "./Component/PlaySound";
import SettingHeading from "./Component/Setting/settingHeading/SettingHeading";
import SetACCharge from "./Component/Setting/subsetting/SetACCharge";
import WaiterManagment from "./Component/Setting/subsetting/WaiterManagment";
import RestaurantStatsMobile from "./pages/RestaurantStatsMobile";
import Ordermain from "./Component/PartnerOrder/Ordermain";
import PartnerIntegration from "./Component/Setting/subsetting/PartnerIntegration";
import { fetchDataAndHandleOrders } from "./service_zomato";

const DB_NAME = "orderDB";
const IN_PROCESS_ORDERS_STORE_NAME = "InProcessOrders";
const COMPLETE_ORDERS_STORE_NAME = "completedOrders";
const ORDER_HISTORY_STORE_NAME = "orderHistory";
const DB_VERSION = 1;

const isMobile = window.innerWidth < 480;



function App() {
    // const navigate = useNavigate();
  const { getDigitalOrders } = useCloud();
  const { processOrder } = useOrder();

  // const [modalOpen, setModalOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
 
  const [digitalOrderReceived, setDigitalOrderReceived] = React.useState(false);
  useEffect(() => {
    const initDB = async () => {
      const db = await openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(IN_PROCESS_ORDERS_STORE_NAME)) {
            db.createObjectStore(IN_PROCESS_ORDERS_STORE_NAME, {
              keyPath: "tableVPA",
            });
          }
          if (!db.objectStoreNames.contains(COMPLETE_ORDERS_STORE_NAME)) {
            db.createObjectStore(COMPLETE_ORDERS_STORE_NAME, {
              keyPath: "billNo",
            });
          }
          if (!db.objectStoreNames.contains(ORDER_HISTORY_STORE_NAME)) {
            db.createObjectStore(ORDER_HISTORY_STORE_NAME, {
              keyPath: "billNo",
            });
          }
          if (!db.objectStoreNames.contains("newOrder")) {
            db.createObjectStore("newOrder", {
              keyPath: "order",
            });
          }
        },
      });
      store.setDBInstance(db);
    };

    initDB();
  }, []);
  useStore();

  useEffect(() => {
    const unsubscribe = onMessage(messaging, async (payload) => {
      handleNewOrder();
    });

    return () => unsubscribe();
  }, []);

  const handleNewOrder = async () => {
    try {
      await playRing();
    } catch (e) {
      console.log(e);
    }
    const orderEvent = new CustomEvent("DIGITAL_ORDER_RECEIVED", {});
    window.dispatchEvent(orderEvent);
  };

  useEffect(() => {
    const checkNewOrder = async () => {
      const db = store.dbInstance;
      if (db) {
        try {
          const order = await db.get("newOrder", "newOrder");
          if (order) {
            await handleNewOrder();
            await db.delete("newOrder", "newOrder");
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    checkNewOrder();
    window.addEventListener("focus", checkNewOrder);
    return () => {
      window.removeEventListener("focus", checkNewOrder);
    };
  }, []);

  useEffect(() => {
    const fetchDigitalOrder = async () => {
      const { event, requestKOTAmoutntItem, requestKot } =
        await getDigitalOrders();
      if (event) {
        // You can add some condition to check if the order has already been processed
        requestKOTAmoutntItem.forEach(async (order) => {
          const formatedOrder = order.items.map((newOrder) => ({
            itemId: newOrder.itemId,
            quantity: newOrder.quantity,
            varientid: newOrder.varientid || undefined,
          }));
          const rawOrder = order.items.map((newOrder) => ({
            uniqueId:
              newOrder.itemId +
              (newOrder.varientid ? `_${newOrder.varientid}` : ``) +
              "-online",
            itemId: newOrder.itemId,
            name: newOrder.name,
            price: newOrder.price || "0",
            variant: newOrder.varientid || [],
            quantity: parseInt(newOrder.quantity),
          }));
          const response = await processOrder({
            rawOrder,
            formatedOrder,
            tableVPA: order.tableVPA,
            cancleOrders: [],
            extraOrders: [],
            username: order.name,
            usermobile: order?.mobileNo || "NA",
            orderStatus: "RequestKOT",
            onlineBillNo: order.onlineCode,
            replaceItems: "RequestKOT",
            isOnline: true,
          });
        });
        const digitalOrdersTables = requestKot.map((table) => table.tableVPA);
        store.setDigitalOrderVPA(digitalOrdersTables);
      }
    };

    // Function to call fetchDigitalOrder on event
    const handleDigitalOrderReceived = () => {
      setDigitalOrderReceived(true);
      fetchDigitalOrder();
    };

    // Add event listener for DIGITAL_ORDER_RECEIVED
    window.addEventListener(
      "DIGITAL_ORDER_RECEIVED",
      handleDigitalOrderReceived
    );

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener(
        "DIGITAL_ORDER_RECEIVED",
        handleDigitalOrderReceived
      );
    };
  }, []);
    // const navigate = useNavigate();

  useEffect(() => {
    // Fetch data periodically
    const interval = setInterval(async() => {
     const count = await fetchDataAndHandleOrders();
     if(count>0){
      toast.loading( <div   onClick={()=>{
        toast.dismiss(toast.id)
      }}>
        New Swiggy/Zomato order!

        <Link to="/order-parcel">
        
        <button
      
          className="bg-green-500 text-white ml-4 px-4 py-2 rounded hover:bg-green-600"
          
          >
          Accept order
        </button>
          </Link>
      </div>, {
       position: "top-right",
       duration:10000,
      
    
       
      
      //  transition: Bounce,
      });
     }
    }, 60000); // Polling interval: 50 seconds 600000

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);


  return (
    <Router>
      <AppContent />
      {/* <Toaster  /> */}
    
      {/* {modalOpen && orderDetails && (
        <Modal onClose={() => setModalOpen(false)}>
          <h2>New Order Received</h2>
          <p>Order ID: {orderDetails.order_id}</p>
          <p>Details: {JSON.stringify(orderDetails)}</p>
          <button onClick={() => setModalOpen(false)}>Close</button>
        </Modal>
      )} */}
      {digitalOrderReceived && (
        <div
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={() => setDigitalOrderReceived(false)}
        >
          <div class="bg-white rounded-lg shadow-lg p-6 w-96 text-center relative">
            <div class="absolute top-[10px] left-1/2 transform -translate-x-1/2 text-3xl text-green-500">
              <i class="fas fa-exclamation-circle"></i>
            </div>
            <div class="text-xl font-semibold flashing text-gray-800 mt-6">
              Digital Order Received
            </div>
          </div>
        </div>
      )}
    </Router>
  );
}

const AppContent = () => {
  const location = useLocation();

  // Conditionally render the Header based on the current route
  const shouldShowHeader =
    !["/login", "/signup", "/newsignup", "/ManagerForm", "/Rooms"].includes(
      location.pathname
    ) && !isMobile;

  return (
    <div className="min-h-screen">
      <Toaster position="top-right"  duration={500} />
      {/* Sidebar - Header component */}
      {shouldShowHeader && (
        <div className="bg-white">
          <Header />
        </div>
      )}

      {/* Main content */}
      <main
        style={{ background: "#f0f0f0" }}
        className={
          shouldShowHeader
            ? "mt-20 ml-40 flex-1 absolute left-0 top-0 right-0 "
            : "flex-1"
        }
      >
        <div
          className={`${
            shouldShowHeader
              ? "max-w-7xl mx-auto py-6 sm:px-6 lg:px-8"
              : "no-spacing-div"
          }`}
        >
          <div
            className={`${
              shouldShowHeader ? "px-4 py-6 sm:px-0" : "no-spacing-route"
            }`}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/newsignup" element={<SignUpNew />} />
              <Route path="/managerform" element={<ManagerForm />} />
              <Route path="/rooms" element={<Room />} />
              <Route
                path="/table-overview"
                element={
                  <AuthGuard>
                    {isMobile ? <RestaurantStatsMobile /> : <TableOverview />}
                  </AuthGuard>
                }
              />
              <Route
                path="/reports"
                element={
                  <AuthGuard>
                    <Reports />
                  </AuthGuard>
                }
              />
              <Route
                path="/accept-order"
                element={
                  <AuthGuard>
                    <AcceptOrder />
                  </AuthGuard>
                }
              />
              <Route
                path="/menu-managment"
                element={
                  <AuthGuard>
                    <ItemManagement />
                  </AuthGuard>
                }
              />
              <Route
                path="/setting"
                element={
                  <AuthGuard>
                    <SettingHeading />
                  </AuthGuard>
                }
              >
                <Route index element={<Setting />} />
                <Route path="/setting/partner-setup" element={<PartnerIntegration />} />
                <Route path="gst-setup" element={<Setting />} />
                <Route path="waiter-management" element={<WaiterManagment />} />
              </Route>
              <Route
                path="/order-list-lifetime"
                element={
                  <AuthGuard>
                    <OrderListLifeTime />
                  </AuthGuard>
                }
              />
              <Route
                path="/order-main"
                element={
                  <AuthGuard>
                    <OrderMain />
                  </AuthGuard>
                }
              />
              <Route
                path="/order-parcel"
                element={
                  <AuthGuard>
                    <Ordermain />
                  </AuthGuard>
                }
              />
              <Route
                path="/hotel-managment"
                element={
                  <AuthGuard>
                    <Managment />
                  </AuthGuard>
                }
              />
              <Route path="*" element={<Navigate to="/table-overview" />} />{" "}
              {/* Redirect to table overview if route is unknown */}
            </Routes>
          </div>
        </div>
      </main>
    </div>
  );
};

export default App;
